.img_contianer {
  position: relative;
}
.fullscreen_button {
  display: flex;
  padding: 2px 3px 2px 3px;
  position: absolute;
  width: 18px;
  height: 18px;
  bottom: 12px;
  right: 12px;
  background: #050505;
  opacity: 0.48;
  border-radius: 3px;
  cursor: pointer;
  > svg path {
    fill: #ffffff;
  }
}
