.withLayout {
  display: flex;
  width: 100%;
  .layoutContainer {
    display: block;
    width: 80%;
    .heading {
      display: block;
      font-size: 35px;
      font-weight: 500;
      height: fit-content;
      line-height: 42px;
      padding-bottom: 10px;
      color: #414141;
      padding-right: 100px;
      border-bottom: 1px solid #ececec;
    }
  }
}

.container {
  margin-top: 22vh;
  width: auto;
  margin-left: 76px;
  margin-right: 80px;
  padding: 44px 49px;
  background-color: #f6f6f6;
}

.form {
  width: inherit;
}

.inputFieldStyle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: fit-content;
  align-items: center;
  justify-items: center;
  margin-bottom: 2rem;
  width: auto !important;
}

.inputFieldStyle label {
  margin-right: 2rem;
}

.inputFieldStyle > :last-child {
  position: absolute;
  bottom: -12px;
  height: 10px;
}

.button {
  margin-top: 2rem;
  text-align: center;
}

.button button {
  width: 260px;
}

@media screen and (max-width: 1366px) {
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .inputFieldStyle {
    display: flex;
    flex-direction: column;
    align-items: unset;
    gap: 18px;

    input {
      width: 330px;
    }
  }
}
