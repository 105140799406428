.container {
  width: 922px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
}

.no_data_found_container {
  display: flex;
  justify-content: center;
  line-height: 51px;
  font-size: 35px;
  color: #f27236;
  height: 250px;
  align-items: center;
}
.button {
  background-color: #f27236;
  background-color: #f27236;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  padding: 10px;
  font-family: 'Nourd';
  font-weight: 500;
  line-height: 21.78px;
  align-items: center;
  font-weight: 500;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  gap: 8px;
}
.content {
  padding-top: 28px;
  padding-bottom: 45px;
  padding-left: 45px;
  padding-right: 45px;

  h3 {
    font-size: 15px;
    font-weight: 500;
    color: black;
    margin-top: 23px;
  }
}

.heading {
  svg {
    float: right;
    cursor: pointer;
  }

  h1 {
    font-family: 'Nourd';
    font-weight: 500;
    font-size: 27px;
    color: black;
    text-align: center;
    line-height: 50px;
  }
}

.container_products {
  height: 250px;
  overflow: auto;
  padding-right: 15px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #d8d8d838;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d8d8d8;
  }
}

.selected_product {
  border: 2px solid gray !important;
}

.container_img {
  cursor: pointer;
  margin-top: 18px;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: #e9f2e9;
  border-radius: 10px;

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
  }

  .viewProduct {
    width: 20%;

    button {
      width: 100%;
      font-size: 14px;
    }
  }

  .specificwidth {
    width: 19% !important;
  }
  .product_detail_container {
    width: 68%;

    .heading {
      display: flex;
      .product_heading {
        width: 27%;
        font-size: 12px;
        color: #636563;
        font-weight: 500;
      }
    }

    .info_content {
      display: flex;
      width: 100%;
      margin-top: 10px;

      h5 {
        color: #414141;
        font-size: 14px;
        line-height: 16.94px;
        font-weight: 500;
      }

      h4 {
        width: 27%;
        font-size: 12px;
        color: #636563;
        font-weight: 500;
        overflow-wrap: break-word;
      }

      h1 {
        font-family: 'Nourd';
        font-weight: 500;
        font-size: 24px;
        line-height: 29.05px;
        margin-top: 5px;
        color: #151515;
        text-align: start;
      }
    }
  }
}

.noItemsScreen > div {
  margin-top: 4rem;

  svg {
    width: 550px;
    height: auto;
  }
}

.upload_section {
  width: 100%;
  height: 72px;
  border: 1.5px dashed #c3c3c3;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #f4f4f4;
  position: relative;
  align-items: center;
  cursor: pointer;

  p {
    font-size: 14px;
    color: #232323;

    button {
      border: none;
      background-color: transparent;
      color: #f27236;
    }
  }

  input {
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    color: #f4f4f4;
  }
}

.checkbox_container {
  display: flex;
  margin-top: 28px;
  align-items: center;
  gap: 11px;

  input {
    width: 19px;
    height: 19px;
  }

  p {
    font-weight: 500;
    font-size: 15px;
    color: #151515;
  }
}

.buttons {
  margin-top: 33px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 191px;
    height: 56px;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 19px;
    font-family: 'Nourd';
    font-weight: 500;

    background-color: #f27236;
  }

  button:nth-child(1) {
    background-color: #f3f3f3;
    border: 1px solid #dddddd;
    color: black;
  }
}

.inputField {
  margin-top: 10px;
  input {
    width: 100%;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 1025px) {
}

@media (max-width: 769px) {
  .container {
    width: auto;
    height: auto;
  }

  .content {
    padding: 20px;
  }

  .buttons {
    flex-direction: column;
    gap: 20px;

    button {
      width: 100%;
    }
  }
}

@media (max-width: 481px) {
}

@media (max-width: 320px) {
}
