.container {
  width: 100%;
}

.attacment_wrapper {
  padding: 1rem;
}

.location_container iframe {
  height: 250px;
}

.video_wrapper div {
  width: 350px !important;
  height: auto !important;
}

.attacment_wrapper img {
  height: auto;
  border-radius: 8px;
}

.attachment_message {
  padding-bottom: 16px !important;
  padding-top: unset !important;
  margin-top: -0.625rem;
}

.PDF_container {
  display: flex;
  align-items: center;
  background-color: #ffffffad;
  border-radius: 8px;
  padding: 10px;
}

.preview_content {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  word-wrap: break-word;
  word-break: break-word;
}

.PDF_container svg {
  width: 50px;
  height: 45px;
  padding: 10px 14px;
}

.PDF_container button svg {
  width: 25px;
  height: 25px;
  overflow: visible;
}

.preview_container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  place-self: flex-start;
}
.preview_container_img_container img {
  width: 110px;
  height: 110px;
}
.preview_container_content_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.content_left {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 26rem;

  align-items: center;
  background-color: #e8e8e8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  p {
    padding: 15px;
    color: #333333;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    align-self: flex-start;
    line-break: auto;
    word-wrap: break-word;
    word-break: break-word;
  }
  .message_ions {
    position: absolute;
    left: -6px;
    bottom: -0px;
    path {
      fill: rgba(72, 150, 74, 0.1215686275);
    }
  }
}

.left_container {
  margin-left: 14px;
  margin-top: 20px;
  display: flex;
  justify-content: left;
  gap: 11px;
  align-items: end;

  .messagerimg {
    width: 39px;
    height: 39px;
    img {
      width: 39px;
      height: 39px;
      border-radius: 50%;
    }
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 1025px) {
  .content_left {
    background-color: #fafafa;

    p {
      font-size: 12px;
      padding: 15px;
    }
    .message_ions path {
      fill: #fafafa !important;
    }
  }
  .left_container {
    margin-left: unset;
    margin-right: 14px;
  }
  .preview_container {
    font-size: 12px;
  }
}

@media (max-width: 769px) {
}

@media (max-width: 481px) {
  .video_wrapper div {
    width: auto !important;
    height: auto !important;
  }
  .PDF_container {
    padding: unset;
  }
  .location_container iframe {
    width: 220px;
  }
  .attacment_wrapper img {
    width: 200px;
  }

  .preview_container {
    flex-direction: column;
  }
}

@media (max-width: 320px) {
}
