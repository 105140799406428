.withLayout {
  display: flex;
  width: 100%;
  .layoutContainer {
    display: block;
    width: 80%;
    .layoutHeading {
      display: block;
      font-size: 35px;
      font-weight: 500;
      height: fit-content;
      line-height: 42px;
      padding-bottom: 10px;
      color: #414141;
      padding-right: 100px;
      border-bottom: 1px solid #ececec;
    }
  }
}

.contactsContainer {
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
  justify-items: start;
  justify-content: center;
  align-items: center;
}

.contactType {
  width: 2.5rem;
  text-align: center;
  background-color: #d4e6d4;
  color: #f27236;
  font-family: Inter;
  font-weight: bold;
  font-size: 14px;
  border: none;
  border-radius: 1px;
  padding: 5px;
  border-radius: 4px;
}

.container {
  margin-top: 41px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 6%;
  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #f27236;
    font-size: 16px;
    font-weight: 500;

    display: flex;
    align-items: center;
    gap: 8px;
  }
  h1 {
    font-family: 'Nourd';
    font-weight: 700;
    font-size: 35px;
    color: #232323;
    margin-top: 18px;
  }
}

.message_container {
  margin-top: 34px;
  display: flex;
  width: 100%;
}

.chat_options_menu {
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  height: fit-content;
  width: auto;
  position: absolute;
  top: 70px;
  right: 25px;
  background: #ffffff;
  box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.127487);
  border-radius: 5px;
}

.chat_options_menu button {
  padding-left: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-right: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #414141;
  line-height: 17px;
  cursor: pointer;
  height: 45px;
  border: none;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 12px;
  background-color: transparent;
}

.chat_options_menu > :first-child {
  border-bottom: 1px solid #ececec;
}

.chats__container {
  max-height: 32rem;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #d8d8d838;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d8d8d8;
  }
}

.messeges {
  border-right: 1px solid #dddddd;
  width: 26rem;
  margin-bottom: 5rem;
}

.content {
  .archived_btn {
    margin-left: 21px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;

    padding: 15px 0;
    color: #000000;
  }
  .archived_btn-active {
    padding: 15px 0;
    width: 100%;
    span {
      color: #000000;
      margin-left: 5rem;
    }
    svg {
      margin-left: 1rem;
    }
  }
}

.tabs {
  &:hover {
    background-color: #edf4ed;
  }
}

.active-tabs {
  background-color: #edf4ed;
}

.content-show {
  width: 100%;
  display: block;

  .Message_inner {
    width: 100%;
  }
  .messages {
    width: 100%;
    height: 431px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #d8d8d838;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #d8d8d8;
    }
  }
}

.message__container > div {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #d8d8d838;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d8d8d8;
  }
}

.message_container_user {
  width: 70%;
  display: none;

  .Message_inner {
    padding-left: 40px;
    padding-right: 27px;
    width: 100%;
  }
  .messages {
    width: 100%;
    height: 431px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #d8d8d838;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #d8d8d8;
    }
  }
}

.container_messages_in {
  width: 70%;
}

.para_container {
  height: 1px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 40px;
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  .today {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10.5px;
    height: 24px;
    width: 118px;
    background-color: #f5f5f5;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #777777;
  }
}

.input_container {
  border-right: 1px solid #dddddd;
  width: 100%;
  height: 94px;
  display: flex;

  align-items: center;
  background-color: #f1f1f1;

  .serchbar {
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    gap: 13px;
    margin: 21px;
    background-color: #ffffff;
    border-radius: 9px;

    .search_icon {
      width: 20px;
      height: 20px;
      margin-left: 21px;
    }
    input {
      outline: none;
      font-size: 16px;
      font-weight: 500;

      border: none;
      border-radius: 9px;

      width: 100%;
      height: 100%;
    }
  }
}

.message_user {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 94px;
  background-color: #f1f1f1;

  .menu_dots {
    width: 10px;
    cursor: pointer;
    margin-right: 30px;
  }
}

.accountant {
  margin-left: 37px;
  gap: 20px;
  display: flex;
  align-items: center;
}
.accoount_avater {
  position: relative;
  width: 49px;
  height: 49px;

  .avatar {
    width: 49px;
    border-radius: 5px;
    object-fit: cover;
    height: 49px;
  }
  .user_img {
    border-radius: 50%;
    border: 1px solid #f27236;
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 24.18px;
    height: 24.18px;
  }
}
.username {
  > div {
    display: flex;
    max-width: 90%;
    gap: 8px;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #000000;
  }

  p {
    // margin-top: 9px;
    font-size: 13px;
    color: #676767;
    font-weight: 400;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 1025px) {
  .chats__container {
    margin-top: 10px;
    display: grid;
    gap: 15px;
  }
  .container {
    padding: 2rem;
  }
  .messeges {
    width: 100%;
    border-right: none;
    flex-direction: column;
    gap: 15px;
  }
  .input_container {
    border-right: none;
    height: 75px;
    background-color: transparent;
    .serchbar {
      background-color: #f4f4f4;
      margin: 0;
      input {
        background-color: #f4f4f4;
      }
    }
  }
  .container_messages_in {
    display: block;
    width: 100%;
  }
  .message_user {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 84px;
    background-color: #f4f4f4;

    .accountant {
      margin-left: 13px;

      .username {
        > div {
          display: flex;
          max-width: 90%;
          gap: 8px;
          align-items: center;
          font-size: 14px;
          line-height: 25px;
        }
        p {
          margin-top: 0;
          line-height: 25px;
          font-size: 10px;
        }
      }
    }
    .menu_dots {
      margin-right: 20px;
    }
  }

  .Message_inner {
    background-color: #edf4ed;
    padding-left: 6px !important;
  }
  .para_container {
    border-bottom: 1px solid #e9e9e9;
    .today {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #777777;
      background-color: #fff;
    }
  }
}
@media (max-width: 769px) {
}

@media (max-width: 481px) {
}

@media (max-width: 320px) {
}
