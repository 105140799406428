.container {
  width: 610px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
}

.content {
  padding-top: 10px;
  padding-bottom: 62px;
  .modalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    .headingContainer {
      font-family: Inter;
      font-size: 35px;
      font-weight: 500;
      line-height: 42px;
      color: #414141;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.cancelContainer {
  width: 95%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  .cancel {
    width: auto;
    height: auto;
    cursor: pointer;
  }
}

.buttonsComponent {
  width: 320px;

  button {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #ffffff;
    cursor: pointer;
    width: 100%;
    background-color: #f27236;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    height: 56px;
  }
}

.dropdownContainer {
  width: 60%;
}

.dropdowninput {
  > div {
    width: 100%;
  }
}

.loader_container {
  height: 200px !important;
}

@media (min-width: 1800px) {
  .container {
    width: 740px;
  }
}

@media (max-width: 1025px) {
}

@media (max-width: 769px) {
}

@media (max-width: 481px) {
}

@media (max-width: 320px) {
}
