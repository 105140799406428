.container {
  width: 100%;
}
.right_container {
  margin-right: 8px;
  margin-top: 20px;
  display: flex;
  gap: 11px;
  align-items: end;
  justify-content: right;
  flex-direction: row-reverse;
}

.messagerimg {
  width: 39px;
  height: 39px;
  img {
    width: 39px;
    height: 39px;
    border-radius: 50%;
  }
}
.location_container iframe {
  height: 250px;
}
.PDF_container {
  display: flex;
  align-items: center;
  background-color: #ffffffad;
  border-radius: 8px;
  padding: 10px;
}
.PDF_container svg {
  width: 50px;
  height: 45px;
  padding: 10px 14px;
}
.PDF_container button svg {
  width: 25px;
  height: 25px;
  overflow: visible;
}
.attacment_wrapper {
  padding: 1rem;
}
.video_wrapper div {
  width: 350px !important;
  height: auto !important;
}
.attacment_wrapper img {
  height: auto;
  border-radius: 8px;
}
.attachment_message {
  padding-bottom: 16px !important;
  padding-top: unset !important;
  margin-top: -0.625rem;
}
.message_ions {
  position: absolute;
  right: -4px;
  transform: rotate(2deg);
  bottom: 0%;
}

.content {
  max-width: 26rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f272361f;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;

  p {
    padding: 15px;
    color: #333333;
    font-weight: 400;
    font-size: 16px;
    line-break: auto;
    word-wrap: break-word;
    word-break: break-word;
    align-self: flex-start;
  }
}
.preview_container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  place-self: flex-start;
}
.preview_container_img_container img {
  width: 110px;
  height: 110px;
}
.preview_container_content_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.preview_content {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  word-wrap: break-word;
  word-break: break-word;
}

@media (max-width: 1025px) {
  .adminMessage {
    font-size: 12px;
  }
  .container {
    .right_container {
      .content {
        background-color: #f272361f;
        p {
          padding: 15px;
          font-size: 12px;
        }
      }
      .message_ions path {
        fill: #d9e9d7f7;
      }
    }
  }
  .preview_container {
    font-size: 12px;
  }
}

@media (max-width: 481px) {
  .video_wrapper div {
    width: auto !important;
    height: auto !important;
  }
  .PDF_container {
    padding: unset;
  }
  .location_container iframe {
    width: 220px;
  }
  .attacment_wrapper img {
    width: 200px;
  }
}

.isAdmin {
  .content {
    background-color: #f27236 !important;
    p {
      color: #ffffff;
    }
  }
  .message_ions path {
    fill: #f27236 !important;
  }
}

.adminMessage {
  color: #ffd500;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 1rem 1rem 0rem 1rem;
  align-self: flex-start;

  + p {
    padding-top: 5px;
  }
}
