.container {
  position: relative;
  padding: 0 10px;
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: black;
  cursor: pointer;
  outline: none;
  border: 2px solid #e4ebe4;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 1rem;
  gap: 2rem;

  font-size: 16px;
  line-height: 19.36px;
  font-weight: 500;

  &:disabled {
    cursor: no-drop !important;
    background-color: #606060 !important;
    color: #f1f1f1 !important;
    svg path {
      fill: white;
    }
  }

  svg path {
    fill: black;
  }

  svg {
    transform: rotate(-90deg);
    transition: 0.5s ease;
  }

  .active svg {
    transform: rotate(-180deg);
  }
}

.options_container {
  position: absolute;
  top: 60px;
  background: #fff;
  width: 100%;
  padding: 0 0 0 10px;
  border-radius: 8px;
  box-shadow: 2px 4px 18px 0px rgba(0, 0, 0, 0.08);
  z-index: 2;
}

.options_seperator {
  width: 90%;
  border: none;
  height: 1px;
  background: #f5f5f5;
  margin: auto;
}

.options_button {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0px 10px;
  border-radius: 7px;
  height: 68px;
  background-color: white;
  border: transparent;
  color: black;
  cursor: pointer;
  &:hover {
    color: #f27236;
  }
}
