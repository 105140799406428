.pagination-container {
  display: flex;
  list-style-type: none;
  padding: 2rem;

  .pagination-item--grey {
    color: #d5d5d5 !important;
  }

  .pagination-item {
    padding: 0 12px;
    height: 34px;
    width: 34px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 44px;
    line-height: 1.43;
    font-size: 14px;
    min-width: 32px;

    :first-child {
      padding: 1.2rem;
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:not(:first-child, :last-child, .selected) {
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        color: black;
        cursor: pointer;
      }
    }

    &.selected {
      background-color: #f27236;
      color: white !important;
    }

    .nextLabel {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #414141;
      cursor: pointer;
      margin-left: 3rem;
    }

    .prevLabel {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #414141;
      cursor: pointer;
      margin-right: 4.5rem;
    }

    .arrow {
      display: flex;
      justify-content: flex-start;
      background-color: #edf4ed;
      border-radius: 44px;
      &::before {
        position: relative;
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-right: 2px solid #f27236;
        border-top: 2px solid #f27236;
      }

      &.left {
        transform: rotate(-135deg);
        margin-right: 20px;
      }

      &.right {
        transform: rotate(45deg);
        margin-left: 20px;
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
