.container {
  width: 100%;
  z-index: 20;
}

.view_product_btn {
  min-width: 140px;
  padding: 10px;
}

.btn_group {
  display: flex;
}

.content {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dddddd;
  align-items: center;
  justify-content: space-between;

  p {
    padding-left: 2rem;
    padding-top: 28px;
    padding-bottom: 32px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #414141;

    b {
      font-weight: 600;
      color: #f27236;
    }
  }
  button {
    margin-right: 1rem;
    margin-left: 1rem;
    width: 127px;
    height: 37px;
    border-radius: 5px;
    border: none;
    display: flex;
    align-items: center;
    gap: 7px !important;
    font-size: 14px !important;
    font-family: 'Nourd';
    font-weight: 700;
    color: #f27236;
    justify-content: center;
    background-color: #d5e7d6 !important;

    &:disabled {
      cursor: not-allowed;
      color: #606060;
      background-color: #f1f1f1 !important;
      svg path {
        fill: #606060;
      }
    }
  }

  .req_for_ins--disabled {
    margin-right: unset;
    background-color: #eaf4ff !important;
    color: #2b94ff !important;
    width: 230px;
    cursor: not-allowed;

    &:disabled {
      color: #98c4f0 !important;
    }

    svg path {
      fill: #98c4f0 !important;
    }
  }

  .req_for_inspection {
    margin-right: unset;
    background-color: #eaf4ff !important;
    color: #2b94ff !important;
    width: 230px;

    &:disabled {
      color: #98c4f0 !important;
    }

    svg path {
      fill: #2b94ff !important;
    }
  }

  button svg {
    overflow: visible !important;
  }
}

.para_container {
  height: 1px;
  border-bottom: 1px solid #f5f5f5;
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  .today {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 20px;
    border-radius: 10.5px;
    height: 24px;
    width: 118px;
    background-color: #f5f5f5;
    color: #7c7c7c;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 1025px) {
  .btn_group {
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    button {
      margin: unset !important;
    }
  }
  .view_product_btn {
    padding: unset;
  }
  .container {
    .content {
      flex-direction: column;
      text-align: left;
      width: 100%;
      align-items: baseline;
      padding-right: 20px;
      padding-bottom: 20px;
      border-bottom: none;
      p {
        padding-top: 28px;
        padding-bottom: 19px;
        font-size: 12px;
        flex-direction: column;
        gap: 10px;
        align-items: baseline;
      }
      button {
        background-color: #f27236 !important;
        color: #ffffff !important;
        width: 90% !important;
        margin-left: 2rem;
        align-self: center;
        height: 37px;
      }
      .req_for_inspection {
        background-color: #017dc5 !important;
        color: #ffffff !important;

        svg path {
          fill: #ffffff !important;
        }
      }
      button svg path {
        fill: #ffffff;
      }
    }
  }
}

@media (max-width: 769px) {
}

@media (max-width: 481px) {
}

@media (max-width: 320px) {
}
