.container {
  width: 617px;
  background-color: #fff;
  border-radius: 10px;
  height: auto;
}

.modal_button {
  margin-top: unset;
  margin-bottom: unset;
}

.singleButton {
  justify-content: center !important;
  button {
    width: 100% !important;
  }
}

.content {
  padding-left: 56px;
  padding-right: 46px;
  padding-top: 33px;
  padding-bottom: 42px;

  h1 {
    font-size: 27px !important;
    font-family: 'Nourd';
    font-weight: 700;
    color: #232323;
    text-align: center;
    line-height: 50px;
    margin-top: 3px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #4c4c4c;
    margin-top: 6px;
    line-height: 29px;
  }
}

.heading {
  .infoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  .cancel {
    cursor: pointer;
    float: right;
  }
}

.delete_buttons {
  margin-top: 34px;
  display: flex;
  align-items: center;
  gap: 20px;

  button {
    font-size: 19px;
    background-color: #f27236;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: #fff;
    height: 56px;
    width: 191px;
    font-family: 'Nourd';
    font-weight: 700;
    line-height: 50px;
    font-weight: 700;
  }

  button:nth-child(1) {
    color: black;
    border: 1px solid #dddddd;
    background-color: #f3f3f3;
    font-family: 'Nourd';
    font-weight: 700;
    line-height: 50px;
    font-weight: 700;
  }
}

.buttons {
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    font-size: 19px;
    background-color: #f27236;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: #fff;
    height: 56px;
    width: 191px;
    font-family: 'Nourd';
    font-weight: 700;
    line-height: 50px;
    font-weight: 700;
  }

  button:nth-child(1) {
    color: black;
    border: 1px solid #dddddd;
    background-color: #f3f3f3;
    font-family: 'Nourd';
    font-weight: 700;
    line-height: 50px;
    font-weight: 700;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 1025px) {
}

@media (max-width: 769px) {
  .container {
    width: 80%;

    height: auto;
    margin-left: 10%;
  }

  .content {
    padding: 20px;
  }

  .buttons {
    margin-top: 10px;
    gap: 20px;
    flex-direction: column;

    button {
      width: 100%;
    }
  }
  .delete_buttons {
    margin-top: 10px;
    gap: 20px;
    flex-direction: column;

    button {
      width: 100%;
    }
  }
}

@media (max-width: 481px) {
}

@media (max-width: 320px) {
}
