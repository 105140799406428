.users_container {
  padding-top: 16px;
  padding-bottom: 25px;
  padding-right: 20px;

  cursor: pointer;
  border-bottom: 1px solid #dddddd;
  gap: 19px;
  display: flex;
  align-items: center;
  width: 100%;
}
.img_container {
  margin-left: 21px;
  position: relative;
  height: 61px;
  width: 61px;

  .img__avatar {
    height: 61px;
    width: 61px;
    border-radius: 5px;
    object-fit: cover;
  }
  .img__user {
    object-fit: cover;

    width: 30px;
    height: 30px;
    position: absolute;
    bottom: -15px;
    right: -15px;
    border-radius: 50%;
    border: 1px solid #f27236;
  }
}
.content {
  width: 100%;

  h2 {
    line-height: 25px;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
  }
  p {
    font-size: 13px;
    line-height: 25px;
    font-weight: 400;
    color: #000000;
    max-width: 120px;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 25px;
    font-size: 13px;
    font-weight: 400;
    color: #646464;
    b {
      font-size: 12px;
      font-weight: 400;
    }
  }
}

@media (max-width: 1200px) {
  .users_container {
    .img_container {
      .img__user {
        width: 22px;
        bottom: -10px;
        right: -10px;
        height: 22px;
      }
    }

    .content {
      h2 {
        font-size: 14px;
      }
      div {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 1025px) {
  .users_container {
    padding-top: 18px;
    border-radius: 10px;
    border-bottom: none;
    padding-bottom: 18px;
    background-color: #edf4ed;
    .img_container {
      margin-left: 9px;
      .img__user {
        width: 22px;
        bottom: -10px;
        right: -10px;
        height: 22px;
      }
    }

    .content {
      h2 {
        line-height: 20px;
      }
      div {
        font-size: 12px;
      }
      p {
        display: none;
      }
    }
  }
}

@media (max-width: 769px) {
}

@media (max-width: 481px) {
}

@media (max-width: 320px) {
}
