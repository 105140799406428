.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  max-width: 90vw;
  max-height: 85vh;
  padding: 2rem 3rem;
  background-color: #fff;
  position: relative;
  border-radius: 10px;
}

.img_container {
  margin-top: 1rem;
}

.container .header svg {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
}

.img_container img {
  width: 600px !important;
  height: auto !important;
  @media (max-width: 768px) {
    width: 450px !important;
  }
  @media (max-width: 550px) {
    width: 280px !important;
  }
}

@media (max-width: 481px) {
  .container {
    width: 100%;
    height: 100%;
    padding: 2rem;
  }
}
