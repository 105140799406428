.container {
  display: flex;
  flex-direction: column;
  position: relative;

  &.lienContainer {
    gap: 0px;
    height: 100%;

    div:nth-child(2) {
      height: 100% !important;
    }
  }

  div > input {
  }
}

.phone_input_button {
  & > div:hover {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  & > div:focus {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
.error {
  font-size: 16px;
  color: #f8a517 !important;
  display: flex !important;
  justify-content: flex-end !important;
  width: 100% !important;
  height: 10px !important;
  font-size: 12px !important;
}
.error_label {
  font-size: 10px;
  color: red;
  padding-left: 5px;
  position: absolute;
  bottom: -15px;
  left: 0;
}
