.withLayout {
  display: flex;
  width: 100%;

  .container {
    display: block;
    width: 80%;

    .heading {
      display: block;
      font-size: 35px;
      font-weight: 500;
      height: fit-content;
      line-height: 42px;
      padding-bottom: 10px;
      color: #414141;
      padding-right: 100px;
      border-bottom: 1px solid #ececec;
    }
  }
}

.top_side {
  margin-top: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 76px;
  padding-right: 104px;

  .profile_side {
    display: flex;
    gap: 25px;
  }
}

.notification_btn {
  position: relative;

  .noti_icon {
    cursor: pointer;
    height: 34px;
    width: 34px;
    position: relative;

    p {
      top: -16px;
      right: -16px;
      position: absolute;
      margin: 0;
      width: 19px;
      height: 19px;
      background-color: #e90000;
      display: flex;
      align-items: center;
      border-radius: 50%;
      color: #fff;
      font-size: 12px;
      font-weight: 700;

      line-height: 14px;
      justify-content: center;
      padding: 0;
    }
  }
}

.profile_drop {
  position: relative;

  .proifle {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: relative;

    img {
      object-fit: cover;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    svg {
      bottom: 10px;
      right: -21px;
      position: absolute;
    }
  }
}

.button_filters {
  margin-top: 53px;
  display: flex;
  justify-content: space-between;
  padding-left: 76px;
  padding-right: 80px;

  .button_creaters {
    display: flex;
    justify-content: right;
    gap: 11px;
    width: auto;

    .creater_button {
      button {
        font-size: 18px;
        height: 55px;
        width: 228px;
      }
    }
  }
}

.inputField {
  input {
    width: 100% !important;
  }
}

.noDataContainer {
  display: flex;
  justify-content: center;
  line-height: 51px;
  font-size: 35px;
  color: #f27236;
  height: 100vh;
  align-items: center;
}
