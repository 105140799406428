.container {
  width: 400px;
  position: relative;

  .searchInput {
    position: absolute !important;
    z-index: 1 !important;
    height: 100% !important;
    width: 78% !important;
    background: transparent !important;
    outline: none !important;
    border: none !important;
    padding-left: 1rem !important;
  }

  ul {
    position: absolute;
    z-index: 99;
    width: 100%;
    border: 1px solid #e5e5e5;
    overflow: hidden;
    max-height: 0px;
    background-color: #fff;
    opacity: 0;
    list-style: none;
    padding: 0px 0;
    margin: -0;
    transition: 0.3s all;

    li {
      cursor: pointer;
      transition: 0.2s;
      padding: 15px 10px;
      font-size: 18px;
      font-weight: 500;

      &:hover {
        background-color: #caf5cb;
      }
    }

    li[aria-selected='true'] {
      background-color: #f27236;
      color: #fff;
    }

    .temp {
      display: none;
    }
    //   li:nth-child(1){
    //     display: none;
    // }
  }

  .show {
    opacity: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #d8d8d838;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #d8d8d8;
    }
  }
  button {
    border-radius: 10px;
    background: #f6f6f6;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;
    height: 50px;
    border: 1px solid #e5e5e5;

    font-size: 16px;
    padding: 6px 22px;
    position: relative;
    text-align: left;
    cursor: pointer;

    transition: 0.3s;

    svg {
      transition: 0.3s;
    }
  }
}

.cancelContainer {
  position: absolute;
  right: 50px;
  top: 17px;
  cursor: pointer;
}

.show {
  opacity: 1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #d8d8d838;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d8d8d8;
  }
  box-shadow: 6px 2px 11px rgba(0, 0, 0, 0.09);
}

ul.options li[aria-selected='true'] {
  background: #f27236 !important;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
}

.error {
  color: #f8a517 !important;
  display: flex !important;
  justify-content: flex-end !important;
  width: 100% !important;
  height: 10px !important;
  font-size: 12px !important;
}

.placeholderColor {
  color: #9a9a9a !important;
}

.customRight {
  right: 10px !important;
}
