.container {
  margin-top: 75px;
  margin-left: 76px;
  width: auto;
  margin-right: 80px;
  height: 60vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #d9d9d9;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #f27236;
  }

  table {
    padding-right: 20px;
    border-collapse: collapse;
    width: 100%;

    thead {
      width: 100%;
      height: 54px;
      background-color: #dadada;
      tr {
        th {
          min-width: 13rem;
          border-left: 1px solid #e6e6e6;
          border-right: 1px solid #e6e6e6;
          &:nth-child(1) {
            border-left: none;
          }
          &:nth-last-child(1) {
            border-right: none;
          }
        }
      }
    }
    tbody {
      width: 100%;
      tr {
        cursor: pointer;
        height: 60px;
        td {
          min-width: 10rem;
          text-align: center;
          border-left: 1px solid #e6e6e6;
          border-right: 1px solid #e6e6e6;
          padding: 5px;

          &:nth-child(1) {
            border-left: none;
          }
          &:nth-last-child(1) {
            border-right: none;
          }
        }
      }
      tr:nth-child(odd) {
        background-color: #f6f6f6;
      }
    }
  }
}

.table_head {
  position: sticky;
  top: 0;
}

.header {
  cursor: default;
  user-select: none;
}

.sortable {
  cursor: pointer;
  user-select: none;

  svg {
    height: 12px;
  }
}
