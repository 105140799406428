.container {
  margin-top: 20px;
  display: flex;
  gap: 11px;
  justify-content: left;
  align-items: flex-end;
  margin-left: 14px;
}

.content {
  margin-right: 20px;
  width: 439px;
  border-radius: 10px;
  position: relative;
  background-color: #e8e8e8 !important ;

  h5 {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #636563;
    margin-top: 11px;
  }
  p {
    line-height: 20px;
    word-wrap: break-word;
    word-break: break-word;
    font-size: 14px;
    font-weight: 400;
    color: #414141;
    margin: 6px 20px 0px 20px;
  }
  .msg_icon {
    position: absolute;
    left: -6px;
    bottom: 0px;
    path {
      fill: #e8e8e8;
    }
  }
}

.img_container {
  margin-top: 18px;
  padding: 0 10px;
  display: flex;
  gap: 15px;
  align-items: center;
  img {
    object-fit: cover;
    width: 121px;
    height: 121px;
    border-radius: 5px;
  }
}

.info_headings {
  p {
    line-height: 20px;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #414141;
  }
  h3 {
    gap: 3px;
    flex-wrap: wrap;
    margin-top: 9px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #414141;
    font-weight: 400;

    b {
      color: #414141;
      font-weight: 400;
    }
  }

  h1 {
    font-size: 24px !important;
    margin-top: 3px !important;
    font-weight: 500 !important;
    color: #151515 !important;
    line-height: 29px !important;
  }
}

.withRightBorder {
  border-right: 1px solid #cedbce !important;
}

.options {
  display: flex;
  width: 100%;
  margin-top: 11px;
  border-top: 1px solid #cedbce;

  button {
    padding: 5px 0;
    background-color: transparent;
    color: #f27236;
    font-size: 14px;
    font-weight: 500;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    span {
      width: 90%;
    }
  }
}

.option-selected {
  display: flex;
  width: 100%;
  margin-top: 11px;
  border-top: 1px solid #cedbce;

  span {
    padding: 17px 0;
    background-color: transparent;
    color: #515151;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.counter-offer-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  @media (max-width: 400px) {
    display: block;
  }
  .strike-thorugh {
    text-decoration: line-through;
    font-size: 20px !important;
  }
  .counter-amount {
    font-size: 20px !important;
  }
}

.user_img {
  width: 39px;
  height: 39px;

  img {
    border-radius: 50%;
    width: 39px;
    height: 39px;
  }
}

.flex_container h1 {
}

@media (max-width: 1200px) {
}

@media (max-width: 1025px) {
  .container {
    margin-left: unset;
    width: 60vw;
  }
  .content {
    width: 100%;
    background-color: #fafafa !important;

    .img_container {
      img {
        width: 76px;
        height: 76px;
      }
      .info_headings {
        p {
          font-size: 12px;
        }
        h3 {
          margin-top: 0;

          font-size: 12px;
        }
        .flex_container {
          display: flex;
          gap: 8px;
          align-items: center;
          @media (max-width: 1024px) {
            display: block;
            h1 {
              line-height: 18px !important;
            }
          }
          h3 {
            font-size: 12px;
          }
          h1 {
            font-weight: 500 !important;

            font-size: 12px !important;
            color: black !important;
          }
        }
      }
    }

    .msg_icon path {
      fill: #fafafa !important;
    }
  }
  .options {
    button {
      font-size: 12px;
    }
  }
  .option-selected {
    span {
      font-size: 12px;
    }
  }
}

@media (max-width: 550px) {
  .container {
    width: unset;
  }
}

@media (max-width: 481px) {
}

@media (max-width: 320px) {
}
