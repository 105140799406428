.withLayout {
  display: flex;
  width: 100%;

  .container {
    display: block;
    width: 80%;

    .heading {
      display: block;
      font-size: 35px;
      font-weight: 500;
      height: fit-content;
      line-height: 42px;
      padding-bottom: 10px;
      color: #414141;
      padding-right: 100px;
      border-bottom: 1px solid #ececec;
    }
  }
}

.top_side {
  margin-top: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 76px;
  padding-right: 104px;

  .profile_side {
    display: flex;
    gap: 25px;
  }
}

.notification_btn {
  position: relative;

  .noti_icon {
    cursor: pointer;
    height: 34px;
    width: 34px;
    position: relative;

    p {
      top: -16px;
      right: -16px;
      position: absolute;
      margin: 0;
      width: 19px;
      height: 19px;
      background-color: #e90000;
      display: flex;
      align-items: center;
      border-radius: 50%;
      color: #fff;
      font-size: 12px;
      font-weight: 700;

      line-height: 14px;
      justify-content: center;
      padding: 0;
    }
  }
}

.profile_drop {
  position: relative;

  .proifle {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: relative;

    img {
      object-fit: cover;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    svg {
      bottom: 10px;
      right: -21px;
      position: absolute;
    }
  }
}

.buttons {
  padding-right: 80px;
  margin-top: 53px;
  display: flex;
  justify-content: right;

  span {
    margin-right: 80px;
    font-size: 18px;
    line-height: 21.78px;
    color: #4a4a4a;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      transform: rotate(90deg);
    }
  }

  .twobtn {
    display: flex;
    gap: 15px;
  }

  .changes_btn {
    button {
      font-size: 18px;

      width: 170px;
      height: 55px;
    }
  }
}

.edit_section {
  margin-top: 53px;
  margin-left: 76px;
  margin-right: 80px;
  padding: 44px 49px;

  background-color: #f6f6f6;
}

.secondheading {
  font-size: 22px;
  font-weight: 600;
  line-height: 26.63px;
  color: #414141;
}

.from_submiting {
  display: flex;
  // padding: 0 50px;
  width: 100%;
  margin-top: 25px;

  .withCheckbox {
    width: 100%;
    display: flex;
    gap: 47px;

    .containerCheck {
      width: 50%;

      .inputCheckbox {
        display: flex;
        gap: 36px;

        .inputField {
          width: 50%;

          input {
            width: 100%;
            background-color: #ffffff;
            color: #232323;
            border: 1px solid #e5e5e5;
            border-radius: 10px;
            // padding: 19px 30px;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
          }
        }
      }
    }
  }

  .formContainer {
    width: 100%;

    .formHeading {
      font-size: 22px;
      font-weight: 600;
      line-height: 26.63px;
      color: #414141;

      margin-top: 38px;
      margin-bottom: 30px;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 19px;

      .inputContainer {
        display: flex;
        gap: 42px;

        .inputField {
          width: 50%;

          input {
            width: 100%;
            background-color: #ffffff;
            color: #232323;
            border: 1px solid #e5e5e5;
            border-radius: 10px;
            // padding: 19px 30px;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
          }
        }
      }
    }
  }

  .inputsfields {
    margin-bottom: 38px;
    width: 50%;
    // padding: 0 100px;

    .dropdown {
      height: 50px;
      display: flex;
      align-items: center;
      gap: 30px;

      p {
        width: 142px;
        font-size: 18px;
        text-align: end;
        color: #636e86;
        font-weight: 500;

        line-height: 21.78px;
      }

      > div {
        display: flex;
        align-items: center;
        gap: 15px;

        .dropdowninput {
          display: none;

          > div {
            width: 292px;

            button {
              font-size: 14px;
              color: #9a9a9a;
              border-radius: 4px;
              height: 41px;
              background-color: #f4f4f4;
              border: 1px solid #f27236;

              > svg:nth-child(1) {
                position: absolute;
                right: 0px;
                border-bottom-right-radius: 4px;
                border-top-right-radius: 4px;
                background-color: #eaeaea;
                height: 100%;
              }

              > svg:nth-child(2) {
                margin-right: 20px;
              }
            }

            ul {
              top: 45px;

              li:nth-last-child(1) {
                display: block;
                text-align: center;
                background-color: #e2eee2;
                color: #f27236;
              }
            }
          }
        }

        .dropdownblock {
          display: block;
        }

        .disblock {
          color: #757575;
          outline: none;
          border: none;
          background-color: transparent;
        }

        .disnone {
          display: none;
        }

        > span {
          display: none;
          cursor: pointer;
        }

        > .dropdownblock1 {
          width: 27px;
          display: flex;
          justify-content: center;
          border-radius: 50%;
          align-items: center;
          background-color: #f27236;
          height: 27px;
        }
      }
    }

    .sec_input {
      height: 50px;
      display: flex;
      justify-content: left;
      margin-top: 38px;
      gap: 30px;
      align-items: center;

      p {
        text-align: end;
        font-size: 18px;
        color: #636e86;
        font-weight: 500;

        line-height: 21.78px;
      }

      .newproder {
        line-height: 25px;
        width: 142px;
        white-space: nowrap;
        text-align: left;
      }

      input {
        font-size: 16px;
        font-weight: 500;
        color: #414141;
        width: 30%;
        background-color: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 10px;
      }
    }

    .input_contin {
      display: flex;
      align-items: center;
      gap: 30px;
      margin-top: 38px;

      p {
        width: 142px;
        font-size: 18px;
        text-align: end;
        color: #636e86;
        font-weight: 500;

        line-height: 21.78px;
      }

      .inputside {
        display: flex;
        align-items: center;
        gap: 16px;

        .inputicon {
          position: relative;

          .inpy {
            border: none;
            width: 292px;
            outline: none;
            background-color: transparent;
            font-size: 16px;
            font-weight: 500;
            color: #414141;
          }

          .tison {
            border: 1px solid #f27236;
          }

          .tempspan {
            display: none;
            width: 16px;
            height: 16px;
            top: 50%;
            left: 20px;
            transform: translate(-50%, -50%);
            position: absolute;
            background-color: transparent;
            border-radius: 2px;
            border: 1px solid #c5c5c5;
          }
        }

        .checked {
          width: 27px;
          height: 27px;
          background-color: #f27236;
          border-radius: 50%;
          display: none;
          align-items: center;
          justify-content: center;

          &:nth-last-child(1) {
            margin-left: -6px;
            background-color: transparent;
          }
        }
      }
    }

    .newcontact {
      .inputside {
        .inputicon {
          .tison {
            border-radius: 4px;
            height: 41px;
            padding-left: 30px;
            border: 1px solid #f27236;
          }

          .tempspan {
            display: block;

            &::placeholder {
              display: none;
            }
          }
        }

        .checked {
          cursor: pointer;
          display: flex;
        }
      }
    }

    .nowrap {
      margin-bottom: 38px;

      p {
        white-space: nowrap;
      }
    }

    .newstyles {
      justify-content: right;

      input {
        width: 250px;
        font-size: 16px;
        font-weight: 500;
        color: #414141;
      }

      h5 {
        display: flex;
        align-items: center;
        gap: 9.5px;
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
        color: #4a4a4a;

        width: 250px;
      }
    }

    > &:nth-child(2) {
      width: 50%;

      .sec_input:nth-child(1) {
        margin-top: 0px;
      }
    }

    .newdropdown {
      > div {
        .dropdowninput {
          > div {
            ul {
              li:nth-last-child(1) {
                display: none;
              }

              li:nth-child(1) {
                display: block;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}

.dropdown {
  height: auto;
  display: flex;
  align-items: center;
  gap: 30px;

  p {
    width: 142px;
    font-size: 18px;
    text-align: end;
    color: #636e86;
    font-weight: 500;

    line-height: 21.78px;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;

    .dropdowninput {
      display: none;

      > div {
        width: 100%;

        button {
          font-size: 14px !important;
          color: #232323;
          border-radius: 4px !important;
          height: 49px !important;
          background-color: #f4f4f4 !important;
          border: 1px solid #f27236 !important;
          padding: 19px 30px !important;

          > svg:nth-child(1) {
            position: absolute;
            right: 0px;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
            background-color: #eaeaea;
            height: 100%;
          }

          > svg:nth-child(2) {
            margin-right: 20px;
          }
        }

        ul {
          top: 45px;
        }
      }
    }

    .dropdownblock {
      display: block;
      width: 100%;
    }

    .disblock {
      color: #757575;
      outline: none;
      border: none;
      background-color: transparent;
    }

    .disnone {
      display: none;
    }

    > span {
      display: none;
      cursor: pointer;
    }

    > .dropdownblock1 {
      width: 27px;
      display: flex;
      justify-content: center;
      border-radius: 50%;
      align-items: center;
      background-color: #f27236;
      height: 27px;
    }
  }
}

.maxWidth {
  width: 100% !important;
}

.gapClass {
  margin-top: 30px;
}

.MSAClass {
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 10px !important;

  ul {
    list-style-type: none !important;
  }
}
