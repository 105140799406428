.conatiner {
  min-width: 100%;
  max-width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}
.button_container {
  margin-top: 15px;
}
.heading_container {
  color: #232323;
  height: 50px;
  font-size: 26px;
  display: flex;
  align-items: center;
  font-family: 'Nourd';
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 20px;
  width: 100%;
}
.logo_side {
  position: relative;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  gap: 3rem;
  h2 {
    position: relative;
    z-index: 2;
    margin-top: 5rem;
    font-size: 35px;
    line-height: 42.36px;
    color: #414141;
  }
  h2 b {
    color: #f27236;
  }
  .svg_img {
    max-width: 100%;
  }
}
.input_password span {
  top: 40%;
}
.formSide {
  margin-bottom: 5rem;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .logo_img {
    margin-bottom: 92.91px;
    cursor: pointer;
  }
  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    .inputfield {
      margin-bottom: 28px;
    }
    .login_btn {
      margin-top: 48px;
      margin-bottom: 42px;
    }
    .link {
      font-size: 16px;
      line-height: 19.36px;
      font-weight: 500;
      color: #f27236;
      text-decoration: underline;
    }
  }
}

.link {
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 500;
  color: #f27236;
  text-decoration: underline;
}

.eye_icon {
  margin-top: 5px;
}

@media (max-width: 1100px) {
  .conatiner {
    min-width: 1100px;
    max-width: 1100px;
  }
}
