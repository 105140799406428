@font-face {
  font-family: 'Nourd';
  font-style: normal;
  font-weight: 300; /* Light */
  src: local('Nourd Light'), url('nourd_light.ttf') format('truetype');
}

@font-face {
  font-family: 'Nourd';
  font-style: normal;
  font-weight: 400; /* Regular */
  src: local('Nourd Regular'), url('nourd_regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nourd';
  font-style: normal;
  font-weight: 500; /* Medium */
  src: local('Nourd Medium'), url('nourd_medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Nourd';
  font-style: normal;
  font-weight: 600; /* Semi-Bold */
  src: local('Nourd Semi-Bold'), url('nourd_semi_bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nourd';
  font-style: normal;
  font-weight: 700; /* Bold */
  src: local('Nourd Bold'), url('nourd_bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nourd';
  font-style: normal;
  font-weight: 800; /* Heavy */
  src: local('Nourd Heavy'), url('nourd_heavy.ttf') format('truetype');
}
