.full_layout {
  top: 0;
  display: flex;
  padding: 0 5%;
  user-select: none;
  align-items: center;
  // width: 90%;
  justify-content: space-between;
  background: red;

  svg {
    cursor: pointer;
  }

  p {
    color: #fff;
    font-size: 14px;
  }

  button {
    margin: 6.6px 0px;
    height: 30.8px;
    width: 162.47px;
    border-radius: 4px;
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    cursor: pointer;
    // transition: 0.5s;

    &:disabled {
      color: #ffffff80;
      border: 1px solid #ffffff80;
      cursor: default;
    }

    @media (max-width: 769px) {
      margin-top: 13px;
      margin-bottom: 0px;
      margin-right: 0px;
      margin-left: 0px;
    }
  }

  @media (max-width: 769px) {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    // width: 90%;
    padding: 13px 5%;
  }
}
@media (max-width: 769px) {
}
@media only screen and (max-width: 769px) and (min-width: 200px) {
  .cancelBtnContainer {
    margin-top: 14px;
  }
}

.notification_layout {
  background: grey !important;
  padding: 10px 5%;

  svg path {
    fill: white;
  }
}

.feedback_layout {
  background: #f27236 !important;
  width: 100%;

  .buttonsContainer {
    display: flex;
    align-items: center;
    gap: 20px;

    .cancelBtnContainer {
      // transition: 0.5s;
      svg {
        path {
          fill: white;
        }
      }

      cursor: pointer;
    }
  }
}

.onboarding_layout {
  background: #f27236 !important;
  border-bottom: 2px solid black;
}
