.withLayout {
  display: flex;
  width: 100%;

  .container {
    display: block;
    width: 80%;

    .heading {
      display: block;
      font-size: 35px;
      font-weight: 500;
      height: fit-content;
      line-height: 42px;
      padding-bottom: 10px;
      color: #414141;
      padding-right: 100px;
      border-bottom: 1px solid #ececec;
    }
  }
}

.top_side {
  margin-top: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 76px;
  padding-right: 104px;

  .profile_side {
    display: flex;
    gap: 25px;
  }
}

.notification_btn {
  position: relative;

  .noti_icon {
    cursor: pointer;
    height: 34px;
    width: 34px;
    position: relative;

    p {
      top: -16px;
      right: -16px;
      position: absolute;
      margin: 0;
      width: 19px;
      height: 19px;
      background-color: #e90000;
      display: flex;
      align-items: center;
      border-radius: 50%;
      color: #fff;
      font-size: 12px;
      font-weight: 700;

      line-height: 14px;
      justify-content: center;
      padding: 0;
    }
  }
}

.profile_drop {
  position: relative;

  .proifle {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: relative;

    img {
      object-fit: cover;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    svg {
      bottom: 10px;
      right: -21px;
      position: absolute;
    }
  }
}

.buttons {
  padding-right: 80px;
  margin-top: 53px;
  display: flex;
  justify-content: right;

  span {
    margin-right: 41px;
    font-size: 18px;
    line-height: 21.78px;
    color: #4a4a4a;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      transform: rotate(90deg);
    }
  }

  .twobtn {
    display: flex;
    gap: 15px;
  }

  .changes_btn {
    button {
      font-size: 18px;

      width: 170px;
      height: 55px;
    }
  }
}

.edit_section {
  margin-top: 53px;
  margin-left: 76px;
  margin-right: 80px;
  padding: 44px 49px;

  background-color: #f6f6f6;
}

.secondheading {
  font-size: 22px;
  font-weight: 600;
  line-height: 26.63px;
  color: #414141;
}

.deals_conatiner {
  padding: 0rem 3rem;
}
