.links {
  min-width: 22.5rem;
  max-width: 22.5rem;

  .logo {
    width: 14rem;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    cursor: pointer;
    align-self: center;
  }
  .bottom_img {
    width: 100%;
    height: 100%;
    margin-top: 2rem;
  }
}
.links_inner {
  display: flex;
  border-right: 1px solid #ececec;
  flex-direction: column;
  min-height: 100vh;
  max-height: fit-content;
  position: sticky;
  background-color: #f6f6f6;
  padding-bottom: 6rem;
  top: 0;
}

.buttons {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  z-index: 3;
  position: relative;
  margin-top: 5vh;
  display: flex;
  gap: 5px;
  flex-direction: column;

  .button_links {
    width: 100%;
    height: 55px;
    border-radius: 8px;
    cursor: pointer;
    color: #414141;
    font-size: 18px;

    line-height: 21.78px;
    align-items: center;
    font-weight: 500;
    border: none;
    background-color: transparent;
    display: flex;
    gap: 8px;
    &:not(:focus) {
      &:hover {
        color: #f27236;
        background-color: rgba(72, 150, 74, 0.11);
        border: 1px solid #f27236;
        svg path {
          fill: #f27236;
        }
      }
    }

    svg {
      overflow: inherit;
      padding-left: 1rem;
      fill: #4a4a4a;
    }
  }
  .active_button {
    background-color: #f27236;
    color: #ffffff;
    svg path {
      fill: #ffffff;
    }
    &:hover {
      border: none !important;
      svg path {
        fill: #ffffff !important;
      }
      color: #ffffff !important;
      background-color: #f27236 !important;
    }
  }
}
.pages {
  width: 80%;
  .content {
    display: none;
  }
  .active_content {
    display: block;
  }
}

@media (max-width: 1200px) {
  .links {
    .logo {
      width: 100%;
      padding-right: 10%;
      padding-left: 5%;
    }
  }
}
