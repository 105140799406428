.container {
  width: 800px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
}
.info_content_inner_contianer {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  margin-top: 16px;
}
.info_content_title {
  color: #414141;
  font-size: 14px;
  width: auto;
  line-height: 17px;
  font-weight: 500;
  max-width: 296px;
}
.info_content_date {
  margin-top: 14px;
  margin-bottom: 16px;
}
.info_content_offer {
  margin-bottom: 3px;
  opacity: 0.7978;
}
.info_content_date span {
  font-weight: 500;
}
.info_content_date,
.info_content_offer {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #414141;
}
.info_content_offer_price {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  color: #151515;
}
.totalAmount {
  padding-left: 15px;
  border-left: 1px solid #cecece;
}
.blur {
  border: 3.5px solid cornflowerblue !important;
  filter: blur(3px);
}
.loaderWrapper {
  height: 505px;

  & > div {
    height: 100%;
  }
}
.amountPaidInputWrapper {
  margin-top: 16px;
}
.amountPaidInputWrapper input {
  width: calc(100% - 18px) !important;
  padding-right: unset !important;
}
.amountPaidInputWrapper span {
  top: 28% !important;
}
.amountPaidInputWrapper > div {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 6px;
}
.description_wrapper {
  margin: unset;
  margin-bottom: 1rem;
}
.description_wrapper > div {
  margin-top: unset;
}
.amountPaidInputWrapper label,
.description_wrapper label {
  font-size: 15px;
  font-weight: 500;
  color: black;
}
.description_wrapper textarea {
  height: 55px;
  background-color: #f4f4f4;
}
.description_wrapper textarea {
  min-height: unset !important;
}
.content {
  width: auto;
  height: auto;
  padding-top: 28px;
  padding-bottom: 45px;
  padding-left: 45px;
  padding-right: 45px;

  h3 {
    font-size: 15px;
    font-weight: 500;
    color: black;
    margin-top: 23px;
  }
}
.heading {
  svg {
    float: right;
    cursor: pointer;
  }
  h1 {
    font-family: 'Nourd';
    font-weight: 500;
    font-size: 27px;
    color: black;
    text-align: center;
    line-height: 50px;
  }
}
.detailsWrapper > div {
  display: flex;
  flex-direction: column;

  & > div {
    width: inherit !important;
    margin-bottom: 60px !important;

    & > div {
      gap: 1rem;
      align-items: flex-start;

      div {
        word-break: normal !important;
      }
    }
  }
}
.container_img {
  margin-top: 18px;
  padding: 20px;
  width: auto;
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: #e9f2e9;
  border-radius: 10px;
  img {
    width: 121px;
    height: 121px;
    object-fit: cover;
    border-radius: 5px;
  }

  .info_content {
    h5 {
      color: #414141;
      font-size: 14px;
      line-height: 16.94px;
      font-weight: 500;
    }
    h4 {
      margin-top: 9px;
      font-size: 14px;
      color: #636563;
      font-weight: 500;
    }
    h1 {
      font-family: 'Nourd';
      font-weight: 500;
      font-size: 24px;
      line-height: 29.05px;
      margin-top: 5px;
      color: #151515;
      text-align: start;
    }
  }
}

.upload_section {
  width: 100%;
  height: 72px;
  border: 1.5px dashed #c3c3c3;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #f4f4f4;
  position: relative;
  align-items: center;
  cursor: pointer;

  p {
    font-size: 14px;
    color: #232323;

    button {
      border: none;
      background-color: transparent;
      color: #f27236;
    }
  }
  input {
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    color: #f4f4f4;
  }
}

.checkbox_container {
  display: flex;
  gap: 11px;

  input {
    width: 19px;
    height: 19px;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    color: #151515;
  }
}

.radioOption {
  display: flex;
  font-size: 15px;
  font-weight: 600;
  gap: 6px;
}

.radioGroup {
  display: flex;
  gap: 10px;
}

.buttons {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 191px;
    height: 56px;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 19px;
    font-family: 'Nourd';
    font-weight: 500;

    background-color: #f27236;
  }
  button:nth-child(1) {
    background-color: #f3f3f3;
    border: 1px solid #dddddd;
    color: black;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 1025px) {
}

@media (max-width: 769px) {
  .container {
    width: auto;
    height: auto;
  }
  .content {
    padding: 20px;
  }
  .buttons {
    flex-direction: column;
    gap: 20px;
    button {
      width: 100%;
    }
  }
}

@media (max-width: 481px) {
}

@media (max-width: 320px) {
}
