.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  a {
    color: #000000;
    text-decoration: none;
  }
  h1 {
    font-size: xx-large;
  }
}

.wrapper-class--read-only {
  padding: 1rem;
  a {
    color: #000000;
    text-decoration: none;
  }
  h1 {
    font-size: xx-large;
  }
}

.editor-class--read-only {
  padding: 0.5rem;
}

.editor-class {
  background-color: #ffffff;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.toolbar-class {
  border: 1px solid #ccc;
  border-radius: 6px;
}

.toolbar-class--read-only {
  display: none;
}

.descriptionErrorContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .error {
    color: #f8a517 !important;
  }
}
