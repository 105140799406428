.attachementName {
  max-width: 4rem;
  font-size: 14px;
  margin: auto;
}

.container form {
  height: 100%;
  max-width: 100%;
  padding-right: 15px;
}
.container {
  width: 100%;
  height: 93px;
  background-color: #f1f1f1;
}
.PDF_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PDF_container svg {
  width: 40px;
  height: 40px;
  padding: 10px 14px;
}
.content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;

  input {
    font-size: 16px;
    color: #333333;
    background-color: transparent;
    font-weight: 400;
    border: none;
    height: 100%;
    outline: none;
    padding-left: 37px;
    width: 100%;
  }
}
.icons {
  margin-left: auto;
  margin-right: 27px;
  display: flex;
  align-items: center;
  gap: 27px;

  svg {
    cursor: pointer;
  }
}

.content_upload {
  display: flex;
  position: absolute;
  bottom: 90px;
  width: 100%;

  gap: 1rem;
  overflow: auto;
  padding: 1rem;
  z-index: 0;
  background: rgb(241 241 241 / 75%);

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #d8d8d838;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d8d8d8;
  }
}

.img_container {
  display: flex;
  position: relative;
  margin-bottom: 5px;
  box-shadow: -1px 3px 20px 0px #80808094;
  border-radius: 18px;
  border: 6px solid white;

  img {
    border-radius: 10px;
  }
}

.cancel {
  top: -10px;
  right: -10px;
  position: absolute;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: #cecece;
  cursor: pointer;
}

.sent_container {
  margin-left: 14px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 50px;
  cursor: pointer;

  background-color: #f27236 !important;

  svg {
    fill: #fff;
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 1025px) {
  .container {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 69px;
    // background-color: #F4F4F4;
    .content {
      width: 100%;
      input {
        width: 100% !important;
      }
      .icons {
        gap: 10px;
        margin-right: 15px;

        svg:nth-child(2) {
          display: none;
        }
        .sent_container {
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 769px) {
}

@media (max-width: 481px) {
  .content {
    input {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      background-color: transparent;
      border: none;
      height: 100%;
      outline: none;
      padding-left: 15px;
      padding-right: 5px;
      width: 100%;
    }
  }
}

@media (max-width: 320px) {
}
