.button {
  cursor: pointer;
  background-color: #4285f4;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  width: 210px;
  height: 38px;
  z-index: 2;
}
