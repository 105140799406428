.alert-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.alert-chat-leave {
  width: 'fit-content';
  padding: '10px 20px';
  margin-top: 'unset';
}

.alert {
  svg {
    overflow: initial;
    margin-right: 5px;
    margin-bottom: -2px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  width: 90%;
  border-radius: 10px;
  padding: 15px;
  height: fit-content;
  margin-left: 2%;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
}

.alert-success {
  background-color: #e9f2e9;
  color: #f27236;
}

.alert-success-1 {
  background-color: #daeadb;
  color: #2f2f2f;
}

.alert-danger {
  background-color: #fed5d5;
  color: #2f2f2f;
}

.alert-info {
  background: #f1f1f1;
}

.alert-light a {
  color: #3a63b7;
  text-decoration: underline;
}

@media (max-width: 1025px) {
  .alert-success,
  .alert-success-1 {
    background-color: #d9e9d9;
  }
  .alert-info {
    background-color: #dcdcdc;
  }
}
@media (max-width: 650px) {
  .alert {
    margin-left: unset;
  }
}
@media (max-width: 450px) {
  .alert {
    height: unset;
  }
}
