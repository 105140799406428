.container {
  height: auto;
  border-radius: 10px;
  background-color: #fff;
  width: 45vw;
}

.textarea_wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.textarea_wrapper > div {
  margin-top: 5px;
  width: 100%;
}

.text_options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  button {
    width: 4rem;
    height: 2rem;
  }
}

.content {
  padding-top: 33px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  width: auto;
  height: 100%;

  textarea {
    height: 209px;
    border-radius: 10px;
    border: 1px solid #e5e5e5;

    font-size: 16px;
    font-weight: 500;
    outline: none;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 25px;
    background-color: #f4f4f4;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    resize: none;
  }
  p {
    margin-top: 21px;
    font-size: 16px;
    font-weight: 600;
    color: #232323;
  }
}

.heading {
  text-align: center;
  svg {
    cursor: pointer;
    float: right;
  }
  h1 {
    display: flex;
    align-items: center;
    justify-content: center;

    line-height: 50px;
    padding-bottom: 25px;
    font-size: 27px;
    color: #232323;
    font-family: 'Nourd';
    font-weight: 500;
  }
}

.buttons {
  display: flex;
  align-items: center;
  margin-top: 29px;

  justify-content: space-between;

  button {
    width: 191px;
    height: 56px;
    font-family: 'Nourd';
    font-weight: 500;
    font-size: 19px;
    cursor: pointer;
    background-color: #f27236;
    border-radius: 10px;
    border: none;
    color: #fff;
  }
  button:nth-child(1) {
    color: black;
    background: #f3f3f3;
  }
}
@media (max-width: 1200px) {
}

@media (max-width: 1025px) {
}

@media (max-width: 769px) {
  .container {
    width: auto;
    height: auto;
  }
  .content {
    padding: 20px;
    width: 100%;

    textarea {
      height: 150px;
    }
    p {
      text-align: center;
    }
  }

  .buttons {
    flex-direction: column;
    gap: 20px;

    button {
      width: 100%;
    }
  }
}

@media (max-width: 481px) {
}

@media (max-width: 320px) {
}
