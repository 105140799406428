.modalWrapper {
  position: relative;
  padding-left: 56px;
  padding-right: 56px;
  padding-top: 33px;
  padding-bottom: 42px;

  @media (max-width: 480px) {
    padding: 2rem;
  }
}

.cancel {
  position: absolute;
  cursor: pointer;
  top: 25px;
  right: 25px;
}

.modalContent {
  background-color: #fff;
  border-radius: 5px;
  text-align: center;

  h1 {
    font-size: 27px !important;
    font-family: 'Nourd';
    font-weight: 700;
    color: #232323;
    text-align: center;
    line-height: 50px;
    margin-top: 3px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #818181;
    margin-top: 16px;
    line-height: 29px;
  }

  @media (max-width: 480px) {
    h1,
    p {
      line-height: unset;
    }
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  button {
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }

  .embedContainer {
    margin-top: 20px;
    iframe {
      width: 100%;
      height: 315px;

      @media (max-width: 480px) {
        height: 250px;
      }
    }
  }
}

.modal_button {
  margin-top: unset;
  margin-bottom: unset;
}

.buttons {
  margin-top: 64px;
  gap: 2rem;

  @media (max-width: 480px) {
    margin-top: 2rem;
  }
}
