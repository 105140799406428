.withLayout {
  display: flex;
  width: 100%;

  .container {
    display: block;
    width: 80%;

    .heading {
      display: block;
      font-size: 35px;
      font-weight: 500;
      height: fit-content;
      line-height: 42px;
      padding-bottom: 10px;
      color: #414141;
      padding-right: 100px;
      border-bottom: 1px solid #ececec;
    }
  }
}

.btnWrapper {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}

.markAllRead {
  color: #f27236;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
  background: inherit;
  border: none;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #f27236;
  border-radius: 50%;
  display: flex;
  margin-left: auto;
  margin-bottom: 1rem;
  margin-right: 3rem;

  @media (max-width: 1025px) {
    position: absolute;
    margin: unset;
    right: 20px;
    bottom: 0;
  }
}

.loaderWrapper {
  svg {
    height: 5rem;
  }
}
.top_container {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #d8d8d838;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d8d8d8;
  }

  .time {
    min-width: max-content;
  }

  .notifications_loader {
    > div {
      height: 5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    > div > div {
      transform: scale(1) !important;
    }
  }

  .loadMore {
    font-family: 'Nourd';
    font-weight: 500;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 50px;
    background-color: #f2f7f2;
    border-radius: 10px;
    border: none;
    padding: 0 22px;
    margin-top: 1rem;

    &:hover {
      background-color: #d5e0d5;
    }
  }

  .Notification_container {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    h1 {
      margin-left: 30px;
      margin-bottom: 28px;

      font-size: 35px;
      font-family: 'Nourd';
      font-weight: 700;
    }

    .notify {
      transition: 0.3s;
      cursor: pointer;
      width: 100%;
      align-items: center;
      padding-bottom: 23px;
      padding-top: 28px;
      display: flex;
      background-color: #ffffff;
      gap: 15px;
      border-radius: 22px;
      border-bottom: 1px solid #ececec;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-left: 30px;
      }

      .post_container {
        display: flex;
        position: relative;
        justify-content: space-between;
        width: 100%;
        align-items: flex-end;

        div {
          h3 {
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
            font-size: 16px;
            font-weight: 400;

            b {
              font-weight: 600;
            }
          }
          p {
            font-size: 16px;
            font-weight: 400;
            margin-top: 5px;
          }
        }
        p {
          font-size: 13px;
          font-weight: 400;
          color: #767676;
          margin-right: 25px;
        }
      }

      &:hover {
        background-color: #f1f7f1;
      }
    }
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 1025px) {
  .loadMore {
    margin-top: unset;
  }
  .top_container {
    margin-top: 42px;
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
  }
  .Notification_container {
    margin-bottom: 60px;
    h1 {
      margin-left: 0px !important;
      text-align: center;
    }
    .notify {
      img {
        margin-left: 0px !important;
      }
      .post_container {
        flex-direction: column;
        align-items: flex-start !important;

        div {
          h3 {
            font-size: 12px !important;
            b {
              font-size: 14px;
            }
          }
          p {
            display: none;
          }
        }
        p {
          margin-top: 15px;
        }
      }
    }
  }
}

@media (max-width: 769px) {
}

@media (max-width: 481px) {
}

@media (max-width: 320px) {
}
