.twoColItems {
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }
}

.button_filters {
  display: flex;
  justify-content: space-between;
  > div {
    width: 20rem;
  }
}

.category_detail_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.category_detail_inner_container {
  display: flex;
}

.waive_deposit_button {
  width: 12rem;
}

.reportFields {
  width: 100% !important;
  padding: 2rem 4rem;

  > div {
    &:not(:last-child) {
      border-bottom: 1px solid #c9c9c9;
    }
  }
}

.reportField {
  height: 96px;
  display: flex;
  padding: 10px 65px;
  align-items: center;
  justify-content: space-between;
  background-color: #f6f6f6;

  .title {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .button {
    width: 112px;
    height: 42px;
  }
}

.fieldLine {
  border-top: 3px solid grey;
  margin-left: auto;
  margin-right: 5rem;
  text-align: right;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 70%;
}

.detailItem {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  div {
    word-break: normal !important;
  }
}

.noPaymentDetails {
  color: red;
  text-align: center;
  width: 80%;
  align-items: center;
  margin: auto;
  margin-top: 2rem;
}

.btn {
  min-width: 90px;
  background-color: #f27236;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;

  line-height: 21.78px;
  align-items: center;
  font-weight: 500;
  border: none;
  text-decoration: none !important;
  gap: 8px;

  &:disabled {
    background-color: #606060 !important;
    color: #f1f1f1 !important;
    cursor: no-drop;
  }
}
.viewInvoice_btn {
  width: 8rem !important;

  button {
    height: 3.5rem;
  }
}
.genrate_invoice_btn button {
  width: 6rem !important;
}
.exemptRegions {
  width: 100% !important;
}
.btn_red {
  background-color: #e03535;
  transition: 0.5s;
  &:hover {
    background-color: #ff4d4d;
  }
}
.multiDetailsBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 50px;

  .singleDetailBox {
    display: flex;
    gap: 20px;
    position: relative;

    &:hover {
      .commentIconContainer {
        display: block !important;
      }
    }

    .labelDetail {
      width: 50%;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
      color: #636e86;
    }

    .videoGridContainer {
      width: 350px;
      padding-right: 20px;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      .videoContainer {
        height: 107px;
        width: 132px;

        video {
          width: 100%;
          height: 100%;
          cursor: pointer;
          border-radius: 6px;
          object-fit: cover;
        }
      }
    }

    .imageGridContainer {
      width: 350px;
      padding-right: 20px;
      display: flex;
      gap: 20px;
      // flex-wrap: wrap;

      .imageContainer {
        height: 107px;
        width: 132px;

        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
          border-radius: 6px;
          object-fit: cover;
        }
      }
    }

    .labelValue {
      width: 50%;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
      color: #4a4a4a;
      word-break: break-all;
      padding-right: 20px;
    }
  }
}
.buttons {
  padding-right: 80px;
  margin-top: 53px;
  display: flex;
  justify-content: right;

  span {
    margin-right: 80px;
    font-size: 18px;
    line-height: 21.78px;
    color: #4a4a4a;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      transform: rotate(90deg);
    }
  }

  .twobtn {
    display: flex;
    gap: 15px;
  }

  .changes_btn {
    button {
      font-size: 18px;

      width: 170px;
      height: 55px;
    }
  }
}

.edit_section {
  margin-top: 53px;
  margin-left: 76px;
  margin-right: 80px;
  padding: 44px 49px;

  background-color: #f6f6f6;
}

.secondheading {
  font-size: 22px;
  font-weight: 600;
  line-height: 26.63px;
  color: #414141;
}

.wireConfirmation_wraper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.wireConfirmation_btn button {
  width: 10rem !important;
  height: 3.5rem;
}

.exemptionTypeAndRegions_btn button {
  width: 12rem;
  height: 3.5rem;
  padding: 0 1rem;
}

.agreementDetails {
  display: block !important;
}

.inputFieldsContainer {
  display: flex;
}

.from_submiting {
  display: flex;
  width: 100%;
  margin-top: 20px;

  .withCheckbox {
    width: 100%;
    display: flex;
    gap: 47px;
    .containerCheck {
      width: 50%;
      .inputCheckbox {
        display: flex;
        gap: 36px;
        .inputField {
          width: 50%;
          input {
            width: 100%;
            background-color: #ffffff;
            color: #232323;
            border: 1px solid #e5e5e5;
            border-radius: 10px;
            // padding: 19px 30px;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
          }
        }
      }
    }
  }

  .formContainer {
    width: 100%;

    .formHeading {
      font-size: 22px;
      font-weight: 600;
      line-height: 26.63px;
      color: #414141;

      margin-top: 38px;
      margin-bottom: 30px;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 19px;

      .inputContainer {
        display: flex;
        gap: 42px;

        .inputField {
          width: 50%;

          input {
            width: 100%;
            background-color: #ffffff;
            color: #232323;
            border: 1px solid #e5e5e5;
            border-radius: 10px;
            // padding: 19px 30px;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
          }
        }
      }
    }
  }

  .inputsfields {
    margin-bottom: 38px;
    width: 50%;
    .customMargin {
      margin-top: 64px !important;
    }
    .dropdown {
      height: 50px;
      display: flex;
      align-items: center;
      gap: 30px;

      p {
        width: 142px;
        font-size: 18px;
        text-align: end;
        color: #636e86;
        font-weight: 500;

        line-height: 21.78px;
      }

      > div {
        display: flex;
        align-items: center;
        gap: 15px;

        .dropdowninput {
          display: none;

          > div {
            width: 292px;

            button {
              font-size: 14px;
              color: #9a9a9a;
              border-radius: 4px;
              height: 41px;
              background-color: #f4f4f4;
              border: 1px solid #f27236;

              > svg:nth-child(1) {
                position: absolute;
                right: 0px;
                border-bottom-right-radius: 4px;
                border-top-right-radius: 4px;
                background-color: #eaeaea;
                height: 100%;
              }

              > svg:nth-child(2) {
                margin-right: 20px;
              }
            }

            ul {
              top: 45px;

              li:nth-last-child(1) {
                display: block;
                text-align: center;
                background-color: #e2eee2;
                color: #f27236;
              }
            }
          }
        }

        .dropdownblock {
          display: block;
        }

        .disblock {
          color: #757575;
          outline: none;
          border: none;
          background-color: transparent;
        }

        .disnone {
          display: none;
        }

        > span {
          display: none;
          cursor: pointer;
        }

        > .dropdownblock1 {
          width: 27px;
          display: flex;
          justify-content: center;
          border-radius: 50%;
          align-items: center;
          background-color: #f27236;
          height: 27px;
        }
      }
    }

    .sec_input {
      display: flex;
      justify-content: left;
      margin-top: 38px;
      gap: 30px;
      align-items: center;
      // @media (min-width: 1500px) {
      //   padding-left: 130px;
      // }
      // @media (min-width: 2000px) {
      //   padding-left: 180px;
      // }
      p {
        text-align: end;
        font-size: 18px;
        color: #636e86;
        font-weight: 500;

        line-height: 21.78px;
      }

      .newproder {
        line-height: 25px;
        width: 142px;
        white-space: nowrap;
        text-align: left;
      }

      .customParagraphTag {
        line-height: 25px;
        width: auto;
        text-align: right;
        width: 50% !important;
      }
      .customInput {
        input {
          border-radius: 0px;
          width: 100%;
          background-color: #f6f6f6;
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: #4a4a4a;
          height: 100%;
        }
      }
      .labelTag {
        width: 50% !important;
        display: flex;
        align-items: center;
        word-break: break-all;
      }

      input {
        font-size: 16px;
        font-weight: 500;
        color: #414141;
        width: 30%;
        background-color: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 10px;
      }
    }

    .input_contin {
      display: flex;
      align-items: center;
      gap: 30px;
      margin-top: 38px;

      p {
        width: 142px;
        font-size: 18px;
        text-align: end;
        color: #636e86;
        font-weight: 500;

        line-height: 21.78px;
      }

      .inputside {
        display: flex;
        align-items: center;
        gap: 16px;

        .inputicon {
          position: relative;

          .inpy {
            border: none;
            width: 292px;
            outline: none;
            background-color: transparent;
            font-size: 16px;
            font-weight: 500;
            color: #414141;
          }

          .tison {
            border: 1px solid #f27236;
          }

          .tempspan {
            display: none;
            width: 16px;
            height: 16px;
            top: 50%;
            left: 20px;
            transform: translate(-50%, -50%);
            position: absolute;
            background-color: transparent;
            border-radius: 2px;
            border: 1px solid #c5c5c5;
          }
        }

        .checked {
          width: 27px;
          height: 27px;
          background-color: #f27236;
          border-radius: 50%;
          display: none;
          align-items: center;
          justify-content: center;

          &:nth-last-child(1) {
            margin-left: -6px;
            background-color: transparent;
          }
        }
      }
    }

    .newcontact {
      .inputside {
        .inputicon {
          .tison {
            border-radius: 4px;
            height: 41px;
            padding-left: 30px;
            border: 1px solid #f27236;
          }

          .tempspan {
            display: block;

            &::placeholder {
              display: none;
            }
          }
        }

        .checked {
          cursor: pointer;
          display: flex;
        }
      }
    }

    .nowrap {
      margin-bottom: 38px;

      p {
        white-space: nowrap;
      }
    }

    .newstyles {
      input {
        width: 250px;
        font-size: 16px;
        font-weight: 500;
        color: #414141;
      }

      h5 {
        display: flex;
        align-items: center;
        gap: 9.5px;
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
        color: #4a4a4a;

        width: 250px;
      }
    }

    > &:nth-child(2) {
      width: 50%;
    }

    .newdropdown {
      > div {
        .dropdowninput {
          > div {
            ul {
              li:nth-last-child(1) {
                display: none;
              }

              li:nth-child(1) {
                display: block;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}

.dropdown {
  height: 50px;
  display: flex;
  align-items: center;
  gap: 30px;

  p {
    width: 142px;
    font-size: 18px;
    text-align: end;
    color: #636e86;
    font-weight: 500;

    line-height: 21.78px;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    .dropdowninput {
      display: none;

      > div {
        width: 100%;

        button {
          font-size: 14px !important;
          color: #232323;
          border-radius: 4px !important;
          height: 49px !important;
          background-color: #f4f4f4 !important;
          border: 1px solid #f27236 !important;
          padding: 19px 30px !important;

          > svg:nth-child(1) {
            position: absolute;
            right: 0px;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
            background-color: #eaeaea;
            height: 100%;
          }

          > svg:nth-child(2) {
            margin-right: 20px;
          }
        }

        ul {
          top: 45px;

          li:nth-last-child(1) {
            display: block;
            text-align: center;
            background-color: #e2eee2;
            color: #f27236;
          }
        }
      }
    }

    .dropdownblock {
      display: block;
      width: 100%;
    }

    .disblock {
      color: #757575;
      outline: none;
      border: none;
      background-color: transparent;
    }

    .disnone {
      display: none !important;
    }

    > span {
      display: none;
      cursor: pointer;
    }

    > .dropdownblock1 {
      width: 27px;
      display: flex;
      justify-content: center;
      border-radius: 50%;
      align-items: center;
      background-color: #f27236;
      height: 27px;
    }
  }
}

.gapClass {
  margin-top: 30px;
}
.contactContainer {
  gap: 50px !important;
  margin-top: 0px !important;
}

.newLine {
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 10px !important;

  ul {
    list-style-type: none !important;
  }
}

.linkContainer {
  overflow-wrap: break-word !important;
  width: 61% !important;
}

.accountsListBox {
  margin-top: 1rem;
  width: 19rem;
  margin-left: auto;
  margin-right: auto;
}

.equipmentListBox {
  margin-top: 1.5rem;
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
}

.more_option_btn {
  position: relative;
  margin-bottom: 10px;

  > div {
    width: auto;
    input {
      cursor: pointer;
    }
    svg {
      display: none;
    }
    button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      height: 3rem;
      width: 100%;
      padding: 10px;
      border-radius: 2px;
      border: 1px solid #c5c5c5;
    }
    ul li {
      padding: 10px;
      font-size: 16px;
    }
  }
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  width: 50%;
  label {
    font-size: 14px;
    font-weight: 500;
    color: #232323;
  }

  textarea {
    font-size: 14px;
    outline: none;
    font-weight: 500;
    color: #4a4a4a;

    min-height: 155px;
    max-height: 155px;
    padding: 12px 14px;
    border: 1px solid #dedede;
    background-color: #f6f6f6;
    max-width: 100%;
    min-width: 100%;
    resize: none;
    height: 202px;
    width: 50%;
    border-radius: 0px;
  }
}

.invoiceSection {
  width: 100%;
}

.invoiceSection tr {
  cursor: unset;
}

.normalAlignItems {
  align-items: normal !important;
}

.containerTable {
  margin-right: 0px !important;
  margin-top: 38px !important;
  margin-left: unset !important;
  height: auto !important;
  table {
    margin-left: 0px !important;
  }
}

.statusContainer {
  text-transform: capitalize;
}

@media (min-width: 1500px) {
  .multiDetailsBox {
    .singleDetailBox {
      .labelDetail {
        text-align: right !important;
      }
    }
  }
}

.vishidden {
  visibility: hidden !important;
}
