.container {
  width: 783px;
  background-color: #fff;
  border-radius: 10px;
  height: 382px;
}

.content {
  padding-left: 56px;
  padding-right: 56px;
  padding-top: 33px;
  padding-bottom: 42px;
  h1 {
    font-size: 27px !important;
    font-family: 'Nourd';
    font-weight: 700;
    color: #232323;
    text-align: center;
    line-height: 50px;
    margin-top: 3px;
  }
}

.heading {
  .infoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cancel {
    cursor: pointer;
    float: right;
  }
}

.feedbackCard {
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #f7f7f7;
  display: flex;
  gap: 40px;
  padding: 33px 33px;
  height: 266px;

  .imageContainer {
    .productImage {
      width: 200px;
      height: 200px;
      position: relative;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .userTypeTag {
        height: 23px;
        width: 61px;
        border-radius: 3px;
        color: #ffffff;
        background-color: #f27236;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-family: Inter;
        font-weight: 500;
        top: 10px;
        left: 10px;
        position: absolute;
      }
    }
  }

  .feedbackDescription {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 21px;
    width: 62%;

    .userInfo {
      display: flex;
      justify-content: space-between;

      .userImageName {
        display: flex;
        gap: 23px;
        justify-content: center;
        align-items: center;

        .userImage {
          width: 60px;
          height: 60px;

          img {
            border-radius: 44px;
            width: 60px;
            height: 60px;
          }
        }

        .userName {
          font-family: 'Nourd';
          font-weight: 700;
          font-size: 18px;
          font-weight: 700;
          line-height: 23px;
          letter-spacing: 0px;
          text-align: left;
          color: #232323;
        }
      }

      .ratingsContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .ratingValue {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0px;
          text-align: left;
          margin-left: 4px;
          color: #393939;
        }
      }
    }

    .userFeedback {
      text-align: left;
      font-family: Inter;
      font-size: 18px;
      font-style: italic;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0px;
      color: #4c4c4c;
      word-break: break-word;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 5px;
        background-color: #d8d8d838;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #d8d8d8;
      }
    }
  }
}
