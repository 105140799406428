.loader {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-bottom: 1rem;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #f7a51e #f7a51e transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent #1fafe5 #1fafe5;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.heading {
  color: #232323;
  font-family: 'Nourd';
  font-weight: 700;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  user-select: none;
  text-align: center;

  @media (max-width: 550px) {
    font-size: 20px;
  }
}

.fullScreenLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100vh;

  svg {
    animation: rotate 1.5s infinite linear;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.heading {
  color: #232323;
  font-family: 'Nourd';
  font-weight: 700;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  user-select: none;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
