.text_area_label {
  gap: 13px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  label {
    font-size: 14px;
    font-weight: 500;
    color: #232323;
  }

  textarea {
    font-size: 14px;
    outline: none;
    font-weight: 500;
    color: #232323;

    border-radius: 10px;
    min-height: 155px;
    max-height: 155px;
    padding-left: 15px;
    padding-top: 15px;
    border: 1px solid #e5e5e5;
    background-color: white;
    max-width: auto;
    min-width: auto;

    &:disabled {
      background-color: lightgray;
      resize: none;
    }
  }
}

.descriptionErrorContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;

  p {
    font-size: 16px;
    font-weight: 500;
    color: #232323;
  }

  .error {
    color: #f8a517 !important;
  }
}
