.container {
  width: 610px;
  background-color: #fff;
  border-radius: 10px;
  height: auto;
}

.content {
  padding-left: 38px;
  padding-right: 28px;
  padding-top: 28px;
  padding-bottom: 37px;

  .headingContainer {
    font-size: 27px;
    font-family: 'Nourd';
    font-weight: 700;
    color: #232323;
    line-height: 50px;
    margin-top: 3px;
  }

  .labelContainer {
    font-size: 17px;
    font-weight: 500;
    color: #000000;
    margin-top: 5px;
    line-height: 21px;
    opacity: 50%;
  }
}

.heading {
  .cancel {
    cursor: pointer;
    float: right;
  }
}

.buttonContainer {
  margin-top: 34px;

  button {
    font-size: 19px;
    width: 100%;
    background-color: #f27236;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: #fff;
    height: 56px;
    font-family: 'Nourd';
    font-weight: 700;
    line-height: 50px;
    font-weight: 700;
  }
}

.feedbackContainer {
  margin-top: 35px;

  textarea {
    width: 100%;
    height: 242px;
    border-radius: 10px;
    color: #232323;
    background-color: #f4f4f4;
    padding: 28px 25px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #e5e5e5;
  }
}

.ratingContainer {
  margin-top: 26px;
}

.error {
  color: #f8a517 !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.centreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px) {
}

@media (max-width: 1025px) {
}

@media (max-width: 769px) {
  .container {
    width: 100%;
    height: auto;
  }

  .content {
    padding: 20px;
  }

  .headingContainer {
    font-size: 15px !important;
  }

  .labelContainer {
    font-size: 15px !important;
  }
}

@media (max-width: 481px) {
}

@media (max-width: 320px) {
}

.imgContainer {
  padding: 20px 20px;
  img {
    // object-fit: cover;
    border-radius: 10px;
    height: 330px;
    width: 100%;
  }
}

.videoContainer {
  video {
    height: 100%;
    width: 100%;
    padding: 20px 0;
  }
}
