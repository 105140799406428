.conatiner {
  min-width: 100%;
  max-width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.logo_side {
  position: relative;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;

  h2 {
    position: relative;
    z-index: 2;
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-size: 35px;

    line-height: 42.36px;
    color: #414141;
  }

  h2 b {
    font-family: inherit;
    color: #f27236;
  }

  .svg_img {
    max-width: 80%;
  }
}

.input_password > div {
  width: 466.1px !important;
}

.formSide {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .logo_img {
    margin-bottom: 92.91px;
  }
  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    .inputfield {
      margin-bottom: 28px;
    }
    .login_btn {
      margin-top: 48px;
      margin-bottom: 42px;
      width: 150px;
    }
    .link {
      font-size: 16px;
      line-height: 19.36px;
      font-weight: 500;
      color: #f27236;
      text-decoration: underline;
    }
  }
}

.link {
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 500;
  color: #f27236;
  text-decoration: underline;
}

.eye_icon {
  margin-top: 5px;
}

@media (max-width: 1100px) {
  .conatiner {
    min-width: 1100px;
    max-width: 1100px;
  }
}
