.btnStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 10px;
}

.btnStyles div {
  // display: contents;
  padding: unset;
}

.btnStyles div svg {
  width: 40px;
  height: auto;
}
.container {
  width: auto;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-direction: row;
    width: 458px;
    height: 47px;
    background-color: #f27236;
    color: #fff;
    border-radius: 8px;

    border: none;
    cursor: pointer;
    font-size: 16px;
    line-height: 19.36px;
    font-weight: 500;

    &:disabled {
      cursor: progress;
    }
  }
}
