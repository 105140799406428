.container {
  background-color: #fff;
  margin-bottom: 2rem;
  border-radius: 10px;
  width: 80vw;
  height: inherit;
}

.img {
  width: auto;
  height: 60vh;
}

.content {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;

  h1 {
    margin-top: 1.25rem;
    font-size: 27px;
    font-family: 'Nourd';
    font-weight: 700;
    color: #232323;
    text-align: center;
    line-height: 50px;
  }
}

.loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.viewer {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.viewer > div {
  overflow: auto;
  height: 65vh;
  scrollbar-width: thin;
  display: grid;
  width: 100%;
  justify-content: center;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #d8d8d838;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d8d8d8;
  }
}

.cancel {
  cursor: pointer;
  float: right;
}

.disnone {
  display: none !important;
}

.action_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  margin-top: 20px;

  div:nth-child(2) {
    width: 34px;
    height: 34px;
    background-color: #e4efe4;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div:nth-child(1) {
    width: 127px;
  }

  div {
    border-radius: 5px;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: right;

    button {
      font-size: 14px;
      background-color: #f27236;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      width: 100%;
      color: #fff;
      height: 34px;
      display: flex;
      gap: 5px;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 920px) and (orientation: landscape) {
  .loader_wrapper {
    height: 50vh;
  }
}

@media (max-width: 920px) {
  .container {
    width: 80vw;
    margin: auto;
  }

  .content {
    padding: 10px 10px 10px 10px;
    position: relative;
  }

  .cancel {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  .viewer {
    margin-top: 1.2rem;
  }

  .viewer > div {
    overflow: auto;
    height: calc(78vh - 16vw);
  }
}

@media (max-width: 481px) {
  .container {
    width: 90%;
    height: 85vh;
    margin: auto;
    padding-bottom: 10px;
  }

  .viewer > div {
    width: 90vw;
    height: 65vh;
  }

  .content {
    padding-bottom: 44px;
  }
}
