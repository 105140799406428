.tableImage {
  width: auto;
  height: 100px;
}

.button_filters {
  margin-top: 53px;
  margin-bottom: 75px;
  display: flex;
  justify-content: flex-end;
}

.table_container {
  margin-top: 0;
  padding-bottom: 4rem;
  margin: 0 20px;
  height: auto;
}

.create_account_btn {
  button {
    font-size: 18px;
    font-weight: 500;
    width: 228px;
    height: 55px;
  }
}

.dropdown_container {
  .filter_btn {
    width: 228px;
    background-color: #f6f6f6;
    height: 55px;
    position: relative;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    font-weight: 500;

    padding-left: 22px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: 0.3s;
    .arrowdown {
      transition: 0.3s;
      position: absolute;
      right: 19px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .downcontainer {
    width: 301px;
    background-color: #f6f6f6;
    height: 0px;
    transition: 0.3s;
    width: 0;
    overflow: hidden;
    position: absolute;

    .innerdiv {
      padding: 21px;
    }
    .checkbox_cont {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      gap: 10px;
      input[type='checkbox'] {
        accent-color: #f27236;
        width: 16px;
        height: 16px;
      }
      p {
        font-size: 14px;
        font-weight: 500;

        white-space: nowrap;
        color: #4a4a4a;
        line-height: 16.94px;
      }
    }
    .checkbox_cont1 {
      display: none;
      margin-left: 26px;
      margin-bottom: 15px;
      .more_option_btn {
        position: relative;
        margin-bottom: 10px;

        > div {
          width: auto;
          button {
            gap: 6.5px;
            display: flex;
            align-items: center;
            border-radius: 2px;
            justify-content: center;

            width: fit-content;
            padding-left: 6px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 6.5px;
            height: 26px;
            border: 1px solid #c5c5c5;
          }
          ul {
            width: 182px;
            li {
              padding: 9.5px 10px;
              font-size: 14px;
              &:nth-child(1) {
                display: block;
              }
            }
          }
        }
      }
      input {
        width: 202px;
        height: 29px;
        color: #414141;
        font-weight: 500;
        outline: none;
        background-color: #f4f4f4;
        border-radius: 2px;
        border: 1px solid #c5c5c5;

        line-height: 16.94px;
        font-size: 14px;
        padding-left: 12px;
      }
    }
    .disblcok {
      display: block;
    }
    .buttons {
      display: flex;
      width: 100%;
      gap: 8px;
      .apply {
        width: 50%;
        height: 36px;
        background-color: #f27236;
        border: none;
        font-size: 14px;
        font-weight: 500;
        color: #fff;

        border-radius: 5px;
        cursor: pointer;
      }
      .clear {
        width: 50%;
        height: 36px;
        font-weight: 500;
        background-color: #e8e8e8;
        border: none;
        font-size: 14px;

        color: black;

        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  .options {
    height: auto;
    width: 301px;
    overflow: hidden;
  }
  .filterbtn_active {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 301px;
    .arrowdown {
      transform: rotate(180deg);
    }
  }
}
