.withLayout {
  display: flex;
  width: 100%;

  .container {
    display: block;
    width: 80%;

    .heading {
      display: block;
      font-size: 35px;
      font-weight: 500;
      height: fit-content;
      line-height: 42px;
      padding-bottom: 10px;
      color: #414141;
      padding-right: 100px;
      border-bottom: 1px solid #ececec;
    }
  }
}

.top_side {
  margin-top: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 76px;
  padding-right: 104px;

  .profile_side {
    display: flex;
    gap: 25px;
  }
}

.notification_btn {
  position: relative;

  .noti_icon {
    cursor: pointer;
    height: 34px;
    width: 34px;
    position: relative;

    p {
      top: -16px;
      right: -16px;
      position: absolute;
      margin: 0;
      width: 19px;
      height: 19px;
      background-color: #e90000;
      display: flex;
      align-items: center;
      border-radius: 50%;
      color: #fff;
      font-size: 12px;
      font-weight: 700;

      line-height: 14px;
      justify-content: center;
      padding: 0;
    }
  }
}

.profile_drop {
  position: relative;

  .proifle {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: relative;

    img {
      object-fit: cover;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    svg {
      bottom: 10px;
      right: -21px;
      position: absolute;
    }
  }
}

.button_filters {
  margin-top: 53px;
  display: flex;
  justify-content: space-between;
  padding-left: 76px;

  > div {
    width: 20rem;
  }
}

.create_account_btn {
  button {
    font-size: 18px;
    font-weight: 500;
    width: 228px;
    height: 55px;
  }
}

.dropdown_container {
  .filter_btn {
    width: 228px;
    background-color: #f6f6f6;
    height: 55px;
    position: relative;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    font-weight: 500;

    padding-left: 22px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: 0.3s;

    .arrowdown {
      transition: 0.3s;
      position: absolute;
      right: 19px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .downcontainer {
    width: 301px;
    background-color: #f6f6f6;
    height: 0px;
    transition: 0.3s;
    width: 0;
    overflow: hidden;
    position: absolute;

    .innerdiv {
      padding: 21px;
    }

    .checkbox_cont {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      gap: 10px;

      input[type='checkbox'] {
        accent-color: #f27236;
        width: 16px;
        height: 16px;
      }

      p {
        font-size: 14px;
        font-weight: 500;

        white-space: nowrap;
        color: #4a4a4a;
        line-height: 16.94px;
      }
    }

    .checkbox_cont1 {
      display: none;
      margin-left: 26px;
      margin-bottom: 15px;

      .more_option_btn {
        position: relative;
        margin-bottom: 10px;

        > div {
          width: auto;

          button {
            gap: 6.5px;
            display: flex;
            align-items: center;
            border-radius: 2px;
            justify-content: center;

            width: fit-content;
            padding-left: 6px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 6.5px;
            height: 26px;
            border: 1px solid #c5c5c5;
          }

          ul {
            width: 182px;

            li {
              padding: 9.5px 10px;
              font-size: 14px;

              &:nth-child(1) {
                display: block;
              }
            }
          }
        }
      }

      input {
        width: 202px;
        height: 29px;
        color: #414141;
        font-weight: 500;
        outline: none;
        background-color: #f4f4f4;
        border-radius: 2px;
        border: 1px solid #c5c5c5;

        line-height: 16.94px;
        font-size: 14px;
        padding-left: 12px;
      }
    }

    .disblcok {
      display: block;
    }

    .buttons {
      display: flex;
      width: 100%;
      gap: 8px;

      .apply {
        width: 50%;
        height: 36px;
        background-color: #f27236;
        border: none;
        font-size: 14px;
        font-weight: 500;
        color: #fff;

        border-radius: 5px;
        cursor: pointer;
      }

      .clear {
        width: 50%;
        height: 36px;
        font-weight: 500;
        background-color: #e8e8e8;
        border: none;
        font-size: 14px;

        color: black;

        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .options {
    height: auto;
    width: 301px;
    overflow: hidden;
  }

  .filterbtn_active {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 301px;

    .arrowdown {
      transform: rotate(180deg);
    }
  }
}

.no_text_transform {
  text-transform: none !important;
}

.inputField {
  input {
    width: 100% !important;
  }
}
