.radioOptions,
.radioOptions label {
  display: flex !important;
  align-items: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #232323 !important;
  white-space: nowrap !important;
}

.radioOptions {
  flex-wrap: wrap;
  margin-top: 36px !important;
  gap: 20px !important;
}

.radioOptions input {
  height: 23px !important;
  width: 23px !important;
  background: #f4f4f4 !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 19px !important;
  margin-right: 13px !important;
  accent-color: #f27236;
}
