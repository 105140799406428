.modal-container {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-container.center {
  align-items: center;
}

.modal-container.top .modal-wrapper {
  margin-top: 93px;
}

.modal-container.top {
  align-items: flex-start;
}

.modal-container.show {
  opacity: 1;
  z-index: 1000;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.modal-wrapper {
  background: #fff;
  border-radius: 10px;
}

@media (max-width: 110%) {
  .modal-container {
    width: 1200px;
  }
}

@media (max-width: 1025px) {
}

@media (max-width: 769px) {
  .modal-wrapper {
    box-shadow: none;
  }
}

@media (max-height: 600px) and (orientation: landscape) {
  .modal-wrapper {
    height: fit-content !important;
  }
  .modal-container {
    overflow: auto !important;
    align-items: unset !important;
  }
}

@media (max-width: 320px) {
}
