.orange {
  display: grid;
  grid-template-columns: 12rem auto;
  margin-bottom: 8px;
  gap: 26px;
  align-items: center;
  font-weight: bold;
  margin: 15px 20px;
  word-break: break-word;
  b {
    font-weight: unset !important;
    color: #232323;
    font-size: 16px;
    font-family: 'Nourd';
    font-weight: 700;
  }
}

.container {
  width: 65vw;
  background-color: #fff;
  border-radius: 10px;
  height: auto;
}

.content {
  padding-top: 10px;
  padding-bottom: 62px;

  .modalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .headingContainer {
      font-family: Inter;
      font-size: 35px;
      font-weight: 500;
      line-height: 42px;
      color: #414141;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.cancelContainer {
  width: 95%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  .cancel {
    width: auto;
    height: auto;
    cursor: pointer;
  }
}

.textAreaContainer {
  width: 90%;
  max-height: 325px;
  overflow: auto;

  .descriptionContainer {
    textarea {
      font-size: 14px;
      outline: none;
      font-weight: 500;
      color: #232323;

      padding: 16px 17px 17px 16px;
      background-color: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 6px;
      width: 95%;
      margin: auto;
      resize: none;
    }
  }
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  gap: 3rem;

  button {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #ffffff;
    cursor: pointer;
    width: 100%;
    background-color: #f27236;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    height: 56px;
    width: 200px;
  }
}
