.withLayout {
  display: flex;
  width: 100%;

  .container {
    display: block;
    width: 80%;

    .heading {
      display: block;
      font-size: 35px;
      font-weight: 500;
      height: fit-content;
      line-height: 42px;
      padding-bottom: 10px;
      color: #414141;
      padding-right: 100px;
      border-bottom: 1px solid #ececec;
    }
  }
}

.button_filters {
  margin-top: 53px;
  display: flex;
  justify-content: space-between;
  padding-left: 76px;
  padding-right: 80px;
}

.tabs {
  display: flex;
  margin-top: 60px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  padding-left: 76px;
  padding-right: 80px;
  width: 100%;

  .tabContent {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid #d9d9d9;
    padding-bottom: 17px;
    cursor: pointer;
    color: #414141;
  }

  .isSelected {
    border-bottom: 3px solid #f27236 !important;
  }
}

.noDataContainer {
  display: flex;
  justify-content: center;
  line-height: 51px;
  font-size: 35px;
  color: #f27236;
  height: 100vh;
  align-items: center;
}
