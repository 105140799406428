.attachment__container {
  position: relative;
}

.attachment_window {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: absolute;
  bottom: 2.2rem;
  right: 0;
  background-color: #ffffff;
  color: black;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 1px 6px rgb(0 0 0 / 13%);
  z-index: 10;
}

.spanContainer {
  cursor: pointer;
}

.attachment_window div {
  display: flex;
  gap: 2rem;
}

.attachment_window span {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.attachment_window span span {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #000000;
}

.attachment_window span input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
}

.attachment_window span svg {
  width: 40px;
  height: auto;
  background: rgba(72, 150, 74, 0.1215686275);
  border-radius: 44px;
  padding: 14px;
  overflow: unset;
}

.attachment_window span svg path,
.attachment_window span svg circle {
  fill: #f27236 !important;
}
