.button {
  margin-left: 4rem;
  background-color: #f27236;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;

  line-height: 21.78px;
  align-items: center;
  font-weight: 500;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  text-decoration: none !important;
  gap: 8px;

  &:disabled {
    cursor: no-drop;
  }
}

.clickableText {
  color: #f27236;
  margin-left: 5px;
  cursor: pointer;
}

.productDescription {
  padding: unset !important;
  margin: 2rem 40px;
  max-height: 22rem;
  overflow-y: auto;
  margin-bottom: 2rem;
}

.viewProductBtn {
  width: 14rem;
  height: 55px;
  gap: 12px;
}

.viewProductBtn svg {
  height: 24px;
  width: 24px;
}

.videoContainer {
  iframe {
    border-radius: 6px;
  }
}

.btn_rfc {
  height: 55px;
  width: 170px;
  margin-left: unset;

  &:disabled {
    background-color: #414141;
    cursor: no-drop;
  }
}

.infoBox {
  text-align: right;
  color: #f27236;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 10px;
  margin-top: 2.5rem;
}

.infoBoxContainer {
  display: flex;
  justify-content: flex-end;
  position: relative;

  svg {
    background: white;
    border-radius: 88px;
    position: absolute;
    height: 28px;
    width: auto;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    right: -10px;
    top: -10px;

    path {
      fill: #f27236;
    }
  }
}

.button svg path {
  fill: #ffffff;
}

.mainContainer,
.buttonsContainer {
  margin: 25px 5% 0% 5%;
}

.mainContainer {
  background-color: #f6f6f6;
  padding-top: 44px;
  padding-bottom: 44px;

  .headingBox {
    padding-left: 68px;
    padding-right: 68px;
    font-family: Inter;
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    color: #414141;
  }

  .productdetailbox {
    padding: 53px 68px;
    display: flex;
    gap: 30px;

    .multiDetailsBox {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 50px;

      .singleDetailBox {
        display: flex;
        gap: 20px;
        position: relative;

        &:hover {
          .commentIconContainer {
            display: block !important;
          }
        }

        .labelDetail {
          width: 50%;
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: #636e86;
        }

        .videoGridContainer {
          width: 350px;
          padding-right: 20px;
          display: flex;
          gap: 20px;
          flex-wrap: wrap;

          .videoContainer {
            height: 107px;
            width: 132px;

            video {
              width: 100%;
              height: 100%;
              cursor: pointer;
              border-radius: 6px;
              object-fit: cover;
            }
          }
        }

        .imageGridContainer {
          width: 350px;
          padding-right: 20px;
          display: flex;
          gap: 20px;
          flex-wrap: wrap;

          .imageContainer {
            height: 107px;
            width: 132px;

            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
              border-radius: 6px;
              object-fit: cover;
            }
          }
        }

        .labelValue {
          width: 50%;
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: #4a4a4a;
          word-break: break-all;
          padding-right: 20px;
        }
      }
    }
  }
}

.checkboxContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

ul {
  list-style-type: none !important;
}

.cursorPointer {
  cursor: pointer;
}

.flexContainer {
  display: flex !important;
}

.gapClass {
  margin-top: 50px !important;
}

.headingSection {
  padding-left: 68px;
  padding-right: 68px;
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  color: #414141;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  label {
    font-size: 14px;
    font-weight: 500;
    color: #232323;
  }

  textarea {
    font-size: 14px;
    outline: none;
    font-weight: 500;
    color: #4a4a4a;

    min-height: 155px;
    max-height: 155px;
    padding: 12px 14px;
    border: 1px solid #dedede;
    background-color: #f6f6f6;
    max-width: 100%;
    min-width: 100%;
    resize: none;
    height: 202px;
    width: 293px;
    border-radius: 0px;
  }
}

.disnone {
  display: none !important;
}

.vishidden {
  visibility: hidden !important;
}

.customInput {
  input {
    border-radius: 0px;
    width: 100%;
    background-color: #f6f6f6;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #4a4a4a;
    height: 100%;
  }
}

.commentIconContainer {
  height: 20px;
  cursor: pointer;
  display: none;
  position: absolute;
  right: 0;
}

.reqChangeContainer {
  position: relative;
}

.reqChangeContainer::after {
  bottom: 0;
  color: #f27236;
  content: '*';
  position: absolute;
  transform: translate(-100%, 0);
}

@media (max-width: 2000px) {
}

@media (min-width: 1500px) {
  .mainContainer {
    .productdetailbox {
      .multiDetailsBox {
        .singleDetailBox {
          .labelDetail {
            text-align: right !important;
          }
        }
      }
    }
  }
}
