/***-Modal-***/
.container {
  width: 80vw;
  height: auto;
  background-color: #fff;
  padding: 0px 40px;
  border-radius: 10px;
  padding-bottom: 2.5rem;
}

.card_img {
  border-radius: 22px;
}

.heading {
  padding: 2rem 2rem 3rem 0;
  svg {
    path {
      fill: #c2c2c2;
    }
    cursor: pointer;
    float: right;
  }
}

.pagination__wrapper > ul {
  padding: unset;
  padding-top: 2rem;
}

.mobile_card_header {
  font-family: 'Nourd';
  font-weight: 500;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
}

.mobile_card_buyer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.mobile_card {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 18px;
  gap: 10px;
  border-radius: 18px;

  div span {
    line-height: unset;
  }
}

.pagination__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

/***-Bid Container-***/

.bid_container {
  display: grid;
  gap: 18px;
  padding: 31px 58.88px 61px 32px;
  background: #f3f3f3;
  border-radius: 12px;
}

.card_header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-family: 'Nourd';
  font-weight: 500;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  background: #f3f3f3;
}

.card_container {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.card {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  font-style: normal;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 15px 10px;
}

.card > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card > div > span {
  margin-left: 17px;
}

@media (max-width: 1025px) {
  .container {
    padding: 10px 28.88px 31px 28px;
  }
  .card {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .card_header {
    font-size: 14px !important;
  }
  .card {
    font-size: 11px;
  }
  .bid_container {
    padding: 20px 20px 31px 20px;
  }
  .heading {
    padding: 1rem 1rem 1.5rem 0;
  }
  .pagination__wrapper li,
  .pagination__wrapper div {
    font-size: 14px !important;
  }
}
