.inputs {
  display: flex;
  flex-direction: column;
  gap: 13px;
  position: relative;
  width: auto;
  height: 49px !important;
  div:nth-child(1) {
    height: 100% !important;
    div:nth-child(1) {
      height: 100% !important;
    }
  }
  a {
    cursor: pointer !important;
  }

  :global(.react-datepicker__triangle) {
    margin-left: -18px !important;
  }
  :global(.react-datepicker-popper) {
    z-index: 99;
  }

  :global(.react-datepicker__tab-loop) {
    margin-top: -13px !important;
  }

  input {
    outline: none;
    border-radius: 10px;
    background-color: #f4f4f4;
    font-size: 16px;
    font-weight: 500;
    color: #232323;

    padding-left: 30px;
    border: 1px solid #e5e5e5;
    height: 100%;

    &:disabled {
      cursor: no-drop !important;
    }
  }
}
