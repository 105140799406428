.error {
  color: #f8a517 !important;
  display: flex !important;
  justify-content: flex-end !important;
}
.container {
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  h1 {
    color: #808080;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}
.categoryUploadSection {
  display: flex;
  gap: 4rem;
}
.formHeading {
  font-size: 22px;
  font-weight: 600;
  line-height: 26.63px;
  color: #414141;

  margin-top: 38px;
  margin-bottom: 30px;
}
.youtube_embed_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.youtube_embed_container svg {
  width: 4rem;
}
.btn_red {
  background-color: #232323;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  z-index: 4;
  height: 38px;
  min-width: 10rem;
  margin-bottom: 10px;
  cursor: pointer;
}
.content_upload > div {
  gap: 22px;
  margin-top: 29px;
  display: flex;
}

.drive_picker_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 16rem;
}

.blur {
  border: 3.5px solid cornflowerblue !important;
  filter: blur(3px);
}

.drag_area {
  padding: 1rem;
  gap: 2rem;
  position: relative;
  margin-top: 22px;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  position: relative !important;
  margin-top: 22px;
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  width: 100%;
  border-radius: 10px;
  border: 1.5px dashed #c3c3c3;
  transition: all 0.1s ease-in-out;
  flex-direction: column;
  p {
    text-align: center;
  }
  @media (max-width: 1300px) {
    padding: 0 1rem;
    flex-direction: column;
    gap: 1rem;
  }
  .icon {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    gap: 2rem;

    p {
      position: absolute;
      top: 75px;
      margin-bottom: -2rem;
    }
  }
  .dirve_button_contianer {
    display: flex;
    justify-content: center;
    margin: 15px 0;

    @media (max-width: 1000px) {
      button {
        padding: 10px;
        width: 120px;
        height: 50px;
      }
    }
  }
  h4 {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-top: 9px;
    color: #232323;
    font-size: 16px;
    font-weight: 500;

    button {
      position: relative;
      // z-index: 4;
      margin-left: 5px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 16px;

      color: #f27236;
    }
  }
  p {
    display: flex;
    justify-content: center;
    padding-bottom: 63px;
    margin-top: 12px;
    font-size: 14px;
    text-align: center;
    color: #ababab;
  }
  input {
    cursor: pointer;
    z-index: 1;

    height: 100%;
    color: #f4f4f4;
    width: 100%;
    position: absolute;
    opacity: 0;
  }
}

.cover_container {
  position: absolute;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Nourd Regular;
  font-size: 12px;
  font-style: normal;
  margin: auto;
  bottom: 10%;
  font-weight: 700;
  line-height: 50px;
  border-radius: 6px;
  background: #f27236;
  width: 101px;
  height: 30px;
}

.content_upload {
  gap: 22px;
  flex-wrap: wrap;
  margin-top: 29px;
  display: flex;

  .card {
    position: relative;
    max-width: 142px;
    user-select: none;
    .img_container_first_child {
      border-radius: 25px !important;
      border: 3px dashed #f27236 !important;
    }
    .img_container {
      position: relative;
      display: flex;
      justify-content: center;
      width: 140px;
      height: 140px;
      box-shadow: -1px 3px 20px 0px #80808094;
      border-radius: 25px;
      border: 2px solid white;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 25px;
      }
      iframe,
      video {
        height: 100%;
        width: 100%;
        border-radius: 25px;
      }
      .cancel {
        top: -10px;
        right: -10px;
        position: absolute;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        background-color: #cecece;
        cursor: pointer;
      }
    }
    h5 {
      margin-top: 18px;
      font-size: 13px;
      font-weight: 500;
      color: #232323;
      max-width: 12rem;
      word-break: break-word;
    }
    p {
      color: #a7a7a7;
      font-size: 13px;
      font-weight: 500;
      margin-top: 5px;
    }
  }
}

@media (max-width: 1200px) {
}

@media (max-width: 1025px) {
  .container {
    width: 100%;
    margin-top: 41px;
    h1 {
      display: none;
    }
    .drag_area {
      width: 100%;
      margin-top: 0;
      text-align: center;
      cursor: pointer !important;
      h4 {
        font-size: 12px;
        button {
          font-size: 12px;
        }
      }
      p {
        font-size: 12px;
      }
    }

    .content_upload {
      .card {
        .img_container {
          width: 77px;
          height: 77px;
          img,
          video {
            width: 77px;
            height: 77px;
          }
        }
      }
    }
  }
}

@media (max-width: 769px) {
}

@media (max-width: 481px) {
}

@media (max-width: 320px) {
}
