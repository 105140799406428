.withLayout {
  display: flex;
  width: 100%;

  .container {
    display: block;
    width: 80%;

    .heading {
      display: block;
      font-size: 35px;
      font-weight: 500;
      height: fit-content;
      line-height: 42px;
      padding-bottom: 10px;
      color: #414141;
      padding-right: 100px;
      border-bottom: 1px solid #ececec;
    }
  }
}

.syncContainer {
  margin-top: 53px;
  padding-left: 76px;
  padding-right: 80px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .headingBox {
    .headingContainer {
      font-size: 22px;
      font-weight: 600;
      line-height: 26.63px;
      color: #414141;
    }
    .buttonContainer {
      width: 100%;
      display: flex;
      gap: 11px;
      margin-top: 20px;

      .syncBtn {
        button {
          font-size: 18px;
          font-weight: 500;
          width: 228px;
          height: 55px;
        }
      }
    }
  }
}
