@import './assets/fonts/style.css';

* {
  font-family: 'Nourd', sans-serif;
}

/****Button Styles****/

.btn-fill-orange {
  transition: background-color 0.5s !important;
  color: #ffffff !important;
  background-color: #f27236 !important;
  cursor: pointer !important;
  &:hover {
    background-color: #f4906c !important;
  }
  &:disabled {
    cursor: progress !important;
    background-color: #606060 !important;
    color: #f1f1f1 !important;
  }
}

.btn-fill-red {
  transition: background-color 0.5s !important;
  color: #ffffff !important;
  background-color: #e03535 !important;
  cursor: pointer !important;
  &:hover {
    background-color: #f27236 !important;
  }
  &:disabled {
    cursor: progress !important;
    background-color: #606060 !important;
    color: #f1f1f1 !important;
  }
}

.btn-fill-grey {
  transition: background-color 0.5s !important;
  background-color: #f2f7f2 !important;
  color: #001e00 !important;
  &:hover {
    background-color: #d5e0d5 !important;
  }
  &:disabled {
    background-color: #606060 !important;
    color: #f1f1f1 !important;
  }
}

.btn-outline {
  transition: background-color 0.5s !important;
  border: 2px solid #f27236 !important;
  background-color: inherit !important;
  color: #f27236 !important;

  &:hover {
    color: #f1f1f1 !important;
    background-color: #10653b !important;
    border: unset !important;
  }
}

.btn-white-outline {
  transition: background-color 0.5s !important;
  color: #f1f1f1 !important;
  background-color: transparent !important;
  border: 2px solid #ffffff !important;
  &:hover {
    background-color: #e2f1e0 !important;
  }
}

.btn-fill-blue {
  transition: background-color 0.5s !important;
  background-color: #017dc5 !important;
  color: white !important;
  &:hover {
    background-color: #0297e4 !important;
  }
}

.btn-fill-text-green {
  transition: background-color 0.5s !important;
  background-color: rgba(16, 138, 0, 0.12) !important;
  color: #f27236 !important;
  &:hover {
    background-color: rgba(16, 138, 0, 0.2) !important;
  }
}

.btn-socials {
  transition: 0.3s !important;
  background-color: #f27236 !important;
  border: transparent !important;
  svg path {
    fill: #ffffff;
  }
  &:hover {
    background-color: #ffffff !important;
    border: 2px solid #f27236 !important;
    svg path {
      fill: #f27236;
    }
  }
}

.btn-outline-round {
  transition: background-color 0.5s !important;
  border: 2px solid #f27236 !important;
  background-color: #ffffff !important;
  color: #f27236 !important;

  &:hover {
    background-color: rgba(16, 138, 0, 0.25) !important;
    border: unset !important;
  }
}

.btn-cancel {
  border: none !important;
  transition: 0.3s !important;
  background-color: #f2f7f2 !important;
  color: #f27236 !important;

  &:hover {
    background-color: #ffe7e7 !important;
    color: #ff1111 !important;
  }

  &:disabled {
    cursor: progress !important;
    background-color: #606060 !important;
    color: #f1f1f1 !important;
  }
}

/********************/

* {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #f27236;
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
@-moz-document url-prefix() {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div,
  span,
  li,
  b,
  button,
  s {
    &:not(.ffBold) {
      font-weight: normal !important;
    }
  }
}

.with-ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  word-wrap: break-word;
  word-break: break-word;
}

.with-ellipsis--single-line {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  word-wrap: break-word;
  word-break: break-word;
}

.pagination__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-container {
  margin-left: 5px;
  position: relative;
  cursor: pointer;
}

.tooltip-container:hover div {
  visibility: visible;
  z-index: 9;
}

.tooltip-container svg {
  margin-bottom: -1px;
  height: 16px;
  width: 26px;
  width: auto;
}

.tooltip-container svg path {
  fill: #4285f4;
}

.public-DraftStyleDefault-ltr {
  margin-left: 4px;
}

.public-DraftStyleDefault-orderedListItem:before {
  width: unset !important;
}

.capitalize {
  text-transform: capitalize;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  gap: 10px;
}
