.pages {
  .content {
    display: none;
  }
  .active_content {
    display: block;
  }
}

.top_side {
  padding-top: 4rem;
  padding-bottom: 2rem;
  border-left: 1px solid #ececec;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 76px;
  padding-right: 104px;

  .profile_side {
    display: flex;
    gap: 25px;
  }
}

.unread_notification_icon {
  pointer-events: none;
  position: absolute;
  right: -2px;
  top: 16px;
}

.dropdown_menu_notification {
  z-index: 10;
  top: 50px;
  background-color: #fff;
  height: 325px;
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  padding: 25px;
  border-radius: 22px;
  box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.165616);
  flex-direction: column;
  width: 500px;
}

.message_heading {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 12px;
}

.message_heading h3 {
  color: #414141;
  font-size: 19px;
  font-weight: 700;
}

.message_heading h4 {
  color: #f27236;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
}

.dot {
  height: 9px;
  width: 9px;
  background-color: #f27236;
  border-radius: 50%;
  margin-left: auto;
  margin-bottom: 1rem;
  margin-right: 3rem;
  position: absolute;
  margin: unset;
  right: 20px;
  bottom: 19.5px;
}

.messages_notification {
  position: relative;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  padding: 12px 10px;
  margin: 5px 0;
  border-radius: 4px;
  border-bottom: 1px solid #ececec;
  gap: 15px;
  align-items: center;

  &:hover {
    background-color: #f1f7f1;
  }
}

.messages_notification:nth-last-child(1) {
  border-bottom: none;
  border: none;
}

.messages_notification img {
  width: 60px;
  height: 60px;
  border-radius: 44px;
}

.message_info div {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.message_info div h3 {
  font-size: 16px;
  font-weight: 600;
  color: #414141;
}

.message_info div p {
  font-size: 16px;
  font-weight: 400;
  color: #414141;
}

.messages_notification img {
  width: 60px;
  height: 60px;
}

.message_info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.profile_drop {
  position: relative;

  .proifle {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: relative;

    img {
      object-fit: cover;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    svg {
      bottom: 10px;
      right: -21px;
      position: absolute;
    }
  }
}

.scroll_message {
  margin-top: 1rem;
  overflow-y: auto;
  height: 300px;
  padding-right: 10px;
  width: 100%;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #d8d8d838;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d8d8d8;
  }
}

.menu_profile {
  button:hover {
    transition: 0.5s;
    color: #f27236;
    svg path {
      fill: #f27236;
    }
  }
}
.notification_btn {
  position: relative;

  .noti_icon {
    cursor: pointer;
    height: 34px;
    width: 34px;
    position: relative;

    svg path {
      fill: #f27236;
      transition: 0.5s;
      &:hover {
        fill: #f27236;
      }
    }

    p {
      top: -16px;
      right: -16px;
      position: absolute;
      margin: 0;
      width: 19px;
      height: 19px;
      background-color: #e90000;
      display: flex;
      align-items: center;
      border-radius: 50%;
      color: #fff;
      font-size: 12px;
      font-weight: 700;

      line-height: 14px;
      justify-content: center;
      padding: 0;
    }
  }
}

.heading {
  display: block;
  font-size: 35px;
  font-weight: 500;
  height: fit-content;
  line-height: 42px;
  padding-bottom: 10px;
  color: #414141;
  padding-right: 100px;
  border-bottom: 1px solid #ececec;
}

.menu_profile {
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 6px 16px;
  height: auto;
  background-color: #fff;
  border-radius: 22px;
  width: auto;
  position: absolute;
  top: 50px;
  box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.165616);
  right: -40px;
}

.menu_profile button {
  cursor: pointer;
  height: 45px;

  border: none;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  color: #414141;
  font-weight: 500;
  background-color: transparent;
  white-space: nowrap;
}

.menu_profile button svg {
  fill: #414141;
}

.menu_profile button:nth-child(5) {
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
}
