.emoji_container {
  position: relative;
  z-index: 4;
}

.emoji_dialog {
  position: absolute;
  bottom: 40px;
  right: 0;
}

.emoji_btn {
  cursor: pointer;
  padding: 5px;
}
