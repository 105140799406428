.container {
  border-radius: 10px;
  background-color: #feeed4;
  padding: 21px 0;

  .orange {
    text-transform: capitalize;
    display: flex;
    margin-bottom: 8px;
    gap: 10px;
    align-items: center;
    margin-left: 23px;
    b {
      color: #232323;
      font-size: 16px;
      font-family: 'Nourd';
      font-weight: 700;
    }
  }
  .error {
    display: none;
    margin-top: 0 !important;
    gap: 5px;
    align-items: center;
    margin-left: 23px;
    b {
      color: #232323;
      font-size: 16px;
      font-family: 'Nourd';
      font-weight: 700;
    }
  }
  .error:nth-child(4) {
    margin-top: 11px !important;
  }
}

.marginTop {
  margin-top: 3px;
}

@media (max-width: 1200px) {
}

@media (max-width: 1025px) {
  .container {
    display: none;
  }
}

@media (max-width: 769px) {
}

@media (max-width: 481px) {
}

@media (max-width: 320px) {
}
