.error {
  color: #f8a517 !important;
  display: flex !important;
  justify-content: flex-end !important;
}
.container {
  width: 972px;
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e8e8e8;
}
.content {
  width: 100%;

  h1 {
    color: #808080;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  label {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #232323;
  }
}
.darg_items {
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  height: 151px;
  background: #f4f4f4;
  border: 1.5px dashed #c3c3c3;

  h3 {
    font-size: 16px;
    color: #232323;
    font-weight: 500;

    button {
      background-color: transparent;
      border: none;

      position: relative;
      cursor: pointer;
      color: #f27236;
      font-size: 16px;
      font-weight: 500;
    }
  }
  input {
    position: absolute;
    top: 0;
    color: #f4f4f4;
    z-index: 3;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.files_container_item {
  display: flex;
  gap: 10px;
  font-size: 15px;
  font-weight: 400;
  color: black;
}

.files_container {
  width: 100%;
  border: 1px solid #e5e5e5;
  margin-top: 23px;
  border-radius: 10px;
}
.file_container_green {
  padding: 22px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;

  h4 {
    font-size: 14px;
    font-weight: 500;
    color: #232323;
    display: flex;
    align-items: center;
    gap: 14px;
  }
  button {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    color: #f27236;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    border: none;
  }
  .pin_icon {
    display: none;
  }
}
.file_container_red {
  padding: 22px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;

  h4 {
    font-size: 14px;
    font-weight: 500;
    color: #232323;
    display: flex;
    align-items: center;
    gap: 14px;
  }
  button {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    color: #e25047;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    border: none;
  }
}

.files_container > div:nth-last-child(1) {
  border-bottom: none;
}

.pdf_icon {
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
}

@media (max-width: 1200px) {
}

@media (max-width: 1025px) {
  .container {
    width: 100%;

    .content {
      h1 {
        display: none;
      }
    }
  }
}

@media (max-width: 769px) {
}

@media (max-width: 481px) {
}

@media (max-width: 320px) {
}
