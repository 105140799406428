.container {
  position: relative;

  .revealContainer {
    button {
      &.err {
        border-color: #f8a517 !important;
        &:hover,
        &:focus {
          border-color: #f8a517 !important;
        }
      }
    }
  }

  .suffix {
    user-select: none;
    position: absolute;
    right: 10px;
    top: 10%;
    color: grey;
  }

  .priceInputField {
    padding-right: 10%;
    width: 100%;
  }

  input {
    height: 56px;
    padding-left: 16.9px;
    width: calc(461px - 16.9px);
    border-radius: 10px;
    background-color: #f4f4f4;
    color: #232323;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    line-height: 16.94px;

    border: 1px solid #e5e5e5;

    &:focus {
      border: 2px solid #c7d6c7;
    }

    &::-ms-reveal {
      display: none;
    }

    &.hasError {
      background-color: rgba(248, 165, 23, 0.0980387) !important;
      border-color: #f8a517 !important;
    }

    &::placeholder {
      color: #7e7e7e;
    }
  }
}

.number_field:disabled {
  background-color: #f6f6f6 !important;
}

.eye_icon {
  margin-top: 5px;
}

.revealContainer {
  display: flex;

  input {
    border-right: none !important;
    border-radius: 8px 0px 0px 8px !important;

    &:focus {
      + button {
        border: 2px solid #c7d6c7 !important;
        border-left: none !important;
      }

      + input {
        border: 2px solid #c7d6c7 !important;
        border-right: none !important;
      }
    }
  }

  button {
    padding: 8px !important;
    border-left: transparent !important;
    border-radius: 0px 8px 8px 0px !important;
  }

  &:hover {
    button {
      border: 2px solid #c7d6c7 !important;
      border-left: none !important;
    }

    input {
      border: 2px solid #c7d6c7 !important;
      border-right: none !important;
    }
  }
}

.eye_icon_container {
  width: unset !important;
  height: unset !important;
  color: unset !important;
  cursor: pointer;
  user-select: none;
  outline: none;
}

.inputIcon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 12px;
}

.arrowStyle {
  top: 14px !important;
}

.error {
  color: #f8a517 !important;
  display: flex !important;
  justify-content: flex-end !important;
  width: 100%;
  height: 100%;
  font-size: 12px !important;
}

.search {
  position: relative;

  > svg {
    position: absolute;
    z-index: 1;
    left: 12px;
    top: 16px;
  }

  input {
    padding-left: 40px !important;
    width: calc(100% - 50px) !important;
    padding-right: 5px;
  }
}
